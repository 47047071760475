<template>
  <div>
    <v-app-bar 
      app
      fixed
      :class="$route.path === '/' ? 'header' : ''"
      :color="$route.path !== '/' ? 'white' : ''"
      elevation="0">
      <v-container>
        <v-row>
          <v-col class="d-flex align-center">
            <nuxt-link 
              to="/">
              <div class="d-flex align-center">
                <div v-if="$route.path === '/' || $route.name === 'index'"
                  class="favicon_header">
                  <!-- FAVICON -->
                </div>

                <v-img v-else
                  center
                  height="50"
                  width="50"
                  contain
                  :src="require('@/assets/logo/favicon.png')">
                </v-img>

                <div 
                  class="title font-weight-bold ml-2"
                  :class="$route.path === '/' ? 'menu-text' : 'black--text'">
                  SITR Online Klaten
                </div>
              </div>
            </nuxt-link>

            <v-spacer/>

            <nuxt-link 
              v-for="(item, index) in menu.left"
              :key="index"
              :to="item.link">
              <v-card-title 
                class="subtitle-1 cursor-pointer d-flex justify-center"
                style="position: relative;">
                <span 
                  :class="$route.path === '/' || $route.name === 'index' ? 'menu-text' : 'black--text'"
                  :style="$route.path !== '/' || $route.name !== 'index' ? 'color: #000000 !important; opacity: 60%;' : ''">
                  {{ item.text }}
                </span>

                <v-divider v-if="item.link === $route.path"
                  :style="$route.path === '/' || $route.name === 'index' ? 'border: 2px solid #FFFFFF;' : 'border: 2px solid #2391d0;'"
                  style="border: 2px solid #FFFFFF;
                        border-radius: 50px;
                        position: absolute;
                        width: 45px;
                        bottom: 10px;" />
              </v-card-title>
            </nuxt-link>

            <v-divider
              class="my-4 menu-divider"
              :class="$route.path === '/' ? 'ml-2 mr-4' : 'ml-2 mr-6'"
              inset
              vertical
              style="height: 50%;">
            </v-divider>

            <v-menu
              transition="slide-x-transition"
              bottom
              right>
              <template v-slot:activator="{ on, attrs }">
                <v-list
                  dense
                  color="transparent"
                  style="width: 18%;">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                    class="px-2"
                    :style="$route.path === '/' ? '' : 'background: #f3f3f3; border-radius: 50px;'">
                    <v-list-item-avatar
                      size="30">
                      <v-img
                        contain
                        width="30"
                        height="30"
                        alt="vuetify"
                        :src="user.image === '' ? require('@/assets/image/member_default.jpg') : user.image">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        :class="$route.path === '/' ? 'white--text' : ''"
                        class="subtitle-1 text-capitalize">
                        {{ user.fullname }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in menu.right"
                  :key="index"
                  @click="toMenu(item)">
                  <v-list-item-content
                    :style="index < menu.right.length - 1 ? 'border-bottom: 2px solid #f9f9f9;' : ''">
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    data () {
      return {
        selected: {
          menu: 0
        },
        menu: {
          left: [
            {
              text: "Beranda",
              link: "/"
            },
            {
              text: "Map",
              link: "/map"
            },
            {
              text: "Panduan",
              link: "/guide"
            },
            {
              text: "Layanan",
              link: "/service"
            },
            {
              text: "Berita",
              link: "/news"
            }
          ],
          right: [
            {
              text: 'Dashboard',
              type: 'dashboard',
              link: '/dashboard'
            },
            {
              text: 'Logout',
              type: 'logout',
              link: '#'
            }
          ],
        }
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()
    },
    methods: {
      async toMenu (item) {
        if (item.type === 'logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')

          window.location = '/'
        } else {
          // NOT LOGOUT
          this.$router.push({ path: item.link })
        }
      }
    }
  }
</script>