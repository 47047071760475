<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            min-height="65.1vh"
            color="transparent">
            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Apa <span class="orange--text">Kata Mereka ?</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </div>

              <v-row 
                class="mb-4"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>
              
              <v-container>
                <v-row 
                  justify="center"
                  class="mb-6">
                  <v-col
                    cols="12" 
                    md="4" 
                    sm="4" 
                    :class="rps === 'xs' ? '' : 'pr-0'">
                    <v-menu
                      rounded
                      offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :class="rps === 'xs' ? 'b_a br_a' : 'b_a br_l'"
                          elevation="0"
                          color="#f0f0f0"
                          block
                          height="40"
                          class="body-2 text-capitalize pr-2 d-flex justify-start"
                          v-bind="attrs"
                          v-on="on">
                          Semua Kategori
                          <v-icon 
                            color="grey darken-1" 
                            style="position: absolute; right: 0px;">
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-menu>
                  </v-col>

                  <v-col 
                    cols="12" 
                    md="8" 
                    sm="8" 
                    :class="rps === 'xs' ? '' : 'pl-0'">
                    <div 
                      class="d-flex align-center justify-end" 
                      style="width: 100%;">
                      <v-text-field
                        :class="rps === 'xs' ? 'br_a' : 'br_r search-style'"
                        outlined
                        dense 
                        single-line
                        hide-details
                        color="#dadada"
                        placeholder="Temukan review yang sesuai kebutuhan Anda . . ."
                        clearable
                        v-model="search"
                        @click:clear="search = ''">
                      </v-text-field>

                      <v-btn
                        style="position: absolute; right: auto; border-radius: 0px 50px 50px 0px;"
                        elevation="0"
                        rounded
                        height="40"
                        color="#2391d0"
                        class="text-capitalize white--text">
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col 
                    cols="12" 
                    md="3"
                    v-for="(item, index) in list"
                    :key="index">
                    <div>
                      <v-card
                        color="white"
                        class="pa-3"
                        style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                        <v-img
                          contain
                          width="55"
                          height="55"
                          class="ma-4 mb-2"
                          alt="vuetify"
                          :src="require('@/assets/image/example/quote.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        
                        <v-card-text
                          class="text-left black--text pb-2">
                          <div
                            class="body-1 font-weight-bold mb-4">
                            {{ item.title }}
                          </div>

                          <div
                            class="body-2 line-text-second"
                            style="opacity: 60%;">
                            {{ item.desc }}
                          </div>
                        </v-card-text>

                        <v-card-text
                          class="text-left">
                          <v-avatar>
                            <v-img
                              alt="vuetify"
                              :src="item.image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <div
                            class="body-2 font-weight-bold black--text mt-3">
                            {{ item.name }}
                          </div>

                          <v-rating
                            v-model="item.rating"
                            :half-increments="false"
                            background-color="orange lighten-3"
                            color="orange"
                            size="14"
                            dense
                            hover>
                          </v-rating>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>

                <Pagination v-if="pagination.total_page > 1"
                  @changePage="fetch($event)" 
                  @reloadPage="fetch($event)"
                  :model="pagination"
                  class="mt-6 mb-16" />
              </v-container>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        search: '',
        list: [
          {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            }
        ],
        pagination: {
          total_data: 3217,
          total_page: 161,
          total_display: 20,
          first_page: false,
          last_page: true,
          prev: 0,
          current: 1,
          limit: 20,
          next: 2,
          detail:[1, 2, 3, 4]
        },
        // SEO
        content: {
          url: '/review',
          title: 'Apa Kata Mereka',
          description: 'Apa Kata Mereka SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      async fetch () {

      }
    }
  }
</script>

<style scoped>
  .search-style.v-text-field--outlined >>> fieldset {
    border-color: #dadada;
    border-left: 0px;
  }
</style>