<template>
  <div 
    style="background: #f3f3f3;">
    <v-card
      flat
      height="30vh"
      color="white"
      style="border-radius: 0px;">
      <v-container
        style="position: absolute;
              bottom: 0;
              left: 0;
              right: 0;">
        <v-row justify="center">
          <v-col 
            cols="12" 
            sm="1"
            class="py-1">
            <nuxt-link to="/">
              <v-img 
                contain 
                width="75"
                height="75"
                :src="require('@/assets/logo/favicon.png')">
              </v-img>
            </nuxt-link>
          </v-col>

          <v-col 
            cols="12" 
            sm="2">
            <div 
              class="body-1 font-weight-bold mb-5">
              SITR Online Klaten
            </div>

            <div v-for="(item, index) in site" :key="index">
              <a :href="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </a>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="2">
            <div 
              class="body-1 font-weight-bold mb-5">
              Informasi
            </div>

            <div 
              v-for="(item, index) in info" 
              :key="index">
              <nuxt-link :to="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </nuxt-link>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="3">
            <div 
              class="body-1 font-weight-bold mb-5">
              Kontak Kami
            </div>

            <div 
              v-for="(item, index) in contact" 
              :key="index">
              <nuxt-link :to="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </nuxt-link>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="4">
            <div class="body-1 font-weight-bold mb-5">
              Berlangganan
            </div>

            <v-row>
              <v-col 
                cols="9" 
                class="pt-0 pb-0 pr-0">
                <v-text-field
                  dense
                  rounded
                  prepend-inner-icon="mdi-email-outline"
                  placeholder="Alamat Email Anda"
                  color="#2391d0"
                  single-line 
                  hide-details="auto"
                  filled>
                </v-text-field>
              </v-col>

              <v-col 
                cols="1"
                class="py-0 pl-0">
                <v-btn
                  style="position: relative; right: 30px; height: 40px;"
                  rounded
                  elevation="0"
                  color="#2391d0">
                  <v-icon color="white">mdi-email-fast-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-avatar 
              v-for="(item, index) in sosmed"
              :key="index"
              class="mt-4 mr-3"
              size="35">
              <a 
                :href="item.link" 
                target="_blank"
                class="d-flex align-center justify-center">
                <v-img
                  contain
                  width="35"
                  height="35"
                  class="ma-auto"
                  :src="item.icon">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#2391d0">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </a>
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div 
      class="white--text background-default">
      <v-container>
        <v-row justify="center">
          <v-col 
            cols="6" 
            class="py-0 px-3">
            <div 
              class="text-left">
              Copyright@{{ new Date().getFullYear() }} <strong>sitr.klaten.go.id</strong>
            </div>
          </v-col>

          <v-col 
            cols="6" 
            class="py-0 px-3">
            <div 
              class="text-right">
              Powered by Pemkab Klaten
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        site: [
          {
            text: 'Tentang Kami',
            link: 'https://dpupr.klaten.go.id'
          },
          {
            text: 'Visi dan Misi',
            link: '/visi'
          },
          {
            text: 'Layanan',
            link: '/service'
          },
          {
            text: 'Berita',
            link: '/news'
          }
        ],
        info: [
          {
            text: 'Syarat & Ketentuan',
            link: '/terms'
          },
          {
            text: 'Kebijakan Privasi',
            link: '/privacy'
          },
          {
            text: 'FAQ',
            link: '/faq'
          }
        ],
        contact: [
          {
            text: 'Alamat: Jl. Sulawesi No. 26 Klaten, Jawa Tengah 57413',
            link: '#'
          },
          {
            text: 'Email: sitrklaten@gmail.com',
            link: '#'
          },
          {
            text: 'Telp: +6281390956104 (WA Center - Chat Only)',
            link: '#'
          }
        ],
        sosmed: [
          {
            icon: require("@/assets/sosmed/instagram.png"),
            link: 'https://www.instagram.com/dpuprklaten'
          },
          {
            icon: require("@/assets/sosmed/twitter.png"),
            link: 'https://twitter.com/DPUPRKlaten/status/1630090670252900352'
          },
          {
            icon: require("@/assets/sosmed/tiktok.png"),
            link: 'https://www.tiktok.com/@dpuprklaten'
          },
          {
            icon: require("@/assets/sosmed/whatsapp.png"),
            link: 'https://api.whatsapp.com/send/?phone=6281390956104&text&type=phone_number&app_absent=0'
          }
        ]
      }
    }
  }
</script>
