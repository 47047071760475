<template>
  <v-app>
    <HeadersPublic v-if="!firstLoad && !token" />
    <HeadersPrivate v-if="!firstLoad && token" />

    <v-sheet 
      light
      style="background: #f3f3f3;"
      min-height="50%">
      <Spinner v-if="firstLoad" />
      
      <nuxt v-if="!firstLoad"/>
    </v-sheet>
    
    <Footers v-if="!firstLoad" />

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="orange"
        @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })">
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  import HeadersPublic from "~/components/public/Header.vue"
  import HeadersPrivate from "~/components/private/Header.vue"
  import Footers from "~/components/Footer.vue"
  import Spinner from "~/components/Spinner.vue"
  export default {
    data () {
      return {
        firstLoad: true,
        fab: false,
        selected: {
          menu: false,
          value: 0
        },
        category: [
          {
            text: "Makanan & Minuman",
            value: "Makanan & Minuman"
          },
          {
            text: "Merchandise",
            value: "Merchandise"
          },
          {
            text: "Pakaian & Fashion",
            value: "Pakaian & Fashion"
          },
          {
            text: "Packaging",
            value: "Packaging"
          },
          {
            text: "Handphone & Tablet",
            value: "Handphone & Tablet"
          },
          {
            text: "Digital Printing",
            value: "Digital Printing"
          },
          {
            text: "Elektronik",
            value: "Elektronik"
          },          
          {
            text: "Komputer & Laptop",
            value: "Komputer & Laptop"
          }
        ]
      }
    },
    components: {
      Spinner,
      HeadersPublic,
      HeadersPrivate,
      Footers
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.firstLoad = false
    },
    beforeCreate() {
      this.firstLoad = true
    },
    created() {
      
    },
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return

        const top = window.pageYOffset || e.target.scrollTop || 0

        this.fab = top > window.innerHeight-100
      }
    }
  }
</script>