<template>
  <div class="fill-height pt-16 mt-8 mb-10"
    id="list">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="min-height: 52vh;">
            <v-row
              justify="center">
              <v-col 
                cols="12" 
                md="3">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3" 
                  style="position: sticky; top: 120px;">
                  <v-list 
                    flat 
                    two-line 
                    class="py-0">
                    <v-list-item 
                      class="px-0">
                      <v-list-item-avatar 
                        tile 
                        size="50" 
                        class="mr-3">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="user.image === '' ?  require('@/assets/image/member_default.jpg') : user.image">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title 
                          class="body-1 text-capitalize font-weight-bold mb-2">
                           {{ user.fullname }}
                        </v-list-item-title>

                        <v-list-item-subtitle 
                          class="body-2 text-capitalize font-weight-light">
                          <span 
                            class="color-first d-flex align-center">
                            <v-icon size="18" color="#2391d0">mdi-seal-variant</v-icon>

                            {{ user.nik }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider 
                    class="mb-3"
                    style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />

                  <div
                    v-for="(item, index) in menu"
                    :key="index">
                    <div 
                      class="px-2">
                      <div 
                        class="body-1 font-weight-bold mb-4">
                        {{ item.title }}
                      </div>

                      <div 
                        v-for="(item_sub, index_sub) in item.sub_menu"
                        :key="index_sub"
                        @click="selected.menu = index; selected.sub_menu = index_sub; changeQuery(item_sub.link)"
                        class="body-2 d-flex align-center cursor-pointer mb-4">
                        <v-icon 
                          size="20"
                          class="mr-2"
                          :color="selected.menu === index && selected.sub_menu === index_sub ? '#2391d0' : '#7B7B7B'">
                          {{ item_sub.icon }}
                        </v-icon>

                        <span 
                          :class="selected.menu === index && selected.sub_menu === index_sub ? 'color-default font-weight-bold' : 'text-second'">
                          {{ item_sub.text }}
                        </span>
                      </div>
                    </div>

                    <v-divider v-if="index < menu.length - 1"
                      class="my-3"
                      style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />
                  </div>
                </v-card>
              </v-col>

              <v-col 
                cols="12" 
                md="9">
                <v-skeleton-loader v-if="process.load"
                  :loading="process.load"
                  type="article, list-item-two-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, list-item-two-line, list-item-three-line">
                </v-skeleton-loader>

                <div v-else>
                  <General v-if="selected.menu === 0 && selected.sub_menu === 0"
                    :detail="detail"
                    @load="fetch()" />

                  <Password v-if="selected.menu === 0 && selected.sub_menu === 1"
                    :detail="detail"
                    @load="fetch()"  />
                  
                  <Announcement v-if="selected.menu === 0 && selected.sub_menu === 2"
                    :detail="detail" />


                  
                  <Submission v-if="selected.menu === 1 && selected.sub_menu === 0"
                    :detail="detail"
                    @goToTop=goToTop() />
                  
                  <Process v-if="selected.menu === 1 && selected.sub_menu === 1"
                    :detail="detail" />

                  <Approved v-if="selected.menu === 1 && selected.sub_menu === 2"
                    :detail="detail" />
                  
                  <Rejected v-if="selected.menu === 1 && selected.sub_menu === 3"
                    :detail="detail" />

                  <Correct v-if="selected.menu === 1 && selected.sub_menu === 4"
                    :detail="detail" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        selected: {
          menu: 0,
          sub_menu: 0
        },
        menu: [
          {
            title: 'Akun Saya',
            sub_menu: [
              {
                text: 'Profil Saya',
                icon: 'mdi-account-reactivate-outline',
                link: '/dashboard?menu=profile'
              },
              {
                text: 'Password',
                icon: 'mdi-shield-key-outline',
                link: '/dashboard?menu=password'
              },
              {
                text: 'Pengumuman',
                icon: 'mdi-bell-outline',
                link: '/dashboard?menu=announcement'
              }
            ]
          },
          {
            title: 'Pengajuan',
            sub_menu: [
              {
                text: 'Ajuan',
                icon: 'mdi-file-document-multiple-outline',
                link: '/dashboard?menu=submission'
              },
              {
                text: 'Diproses',
                icon: 'mdi-file-document-arrow-right-outline',
                link: '/dashboard?menu=process'
              },
              {
                text: 'Disetujui',
                icon: 'mdi-file-document-check-outline',
                link: '/dashboard?menu=approved'
              },
              {
                text: 'Ditolak',
                icon: 'mdi-file-document-remove-outline',
                link: '/dashboard?menu=rejected'
              },
              {
                text: 'Dikoreksi',
                icon: 'mdi-file-document-edit-outline',
                link: '/dashboard?menu=correct'
              }
            ]
          }
        ],
        detail: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/dashboard',
          title: 'Dashboard',
          description: 'Dashboard SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      General: () => import('@/components/dashboard/general'),
      Password: () => import('@/components/dashboard/password'),
      Announcement: () => import('@/components/dashboard/announcement'),

      Submission: () => import('@/components/dashboard/submission'),
      Process: () => import('@/components/dashboard/process'),
      Approved: () => import('@/components/dashboard/approved'),
      Rejected: () => import('@/components/dashboard/rejected'),
      Correct: () => import('@/components/dashboard/correct')
    },
    watch: {
      '$route.query.menu': function(val) {
        this.changeLink(val)
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    beforeMount () {
      this.changeLink(this.$route.query.menu)
    },
    mounted () {
      this.fetch()
    },
    methods: {
      changeLink(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        if (query !== undefined) {
          if (query === 'profile') {
            this.selected = {
              menu: 0,
              sub_menu: 0
            }
          } else if (query === 'password') {
            this.selected = {
              menu: 0,
              sub_menu: 1
            }
          } else if (query === 'inbox') {
            this.selected = {
              menu: 0,
              sub_menu: 2
            }
          } else if (query === 'notification') {
            this.selected = {
              menu: 0,
              sub_menu: 3
            }
          } else if (query === 'submission') {
            this.selected = {
              menu: 1,
              sub_menu: 0
            }
          } else if (query === 'process') {
            this.selected = {
              menu: 1,
              sub_menu: 1
            }
          } else if (query === 'approved') {
            this.selected = {
              menu: 1,
              sub_menu: 2
            }
          } else if (query === 'rejected') {
            this.selected = {
              menu: 1,
              sub_menu: 3
            }
          } else if (query === 'correct') {
            this.selected = {
              menu: 1,
              sub_menu: 4
            }
          }
        }
      },
      changeQuery(link) {
        this.$router.push(link)
      },
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.detail = response.data
          } 
        })
      },
      goToTop () {
        let element = document.getElementById('list'),
            headerOffset = 45,
            elementPosition = element.getBoundingClientRect().top,
            offsetPosition = elementPosition + window.pageYOffset - headerOffset
      
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }
</script>