<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container v-if="process.load && Object.keys(detail).length < 1"
            class="pb-0">
            <v-row>
              <v-col 
                cols="12"
                md="9"
                class="pb-0">
                <v-skeleton-loader
                  type="article, image, article, list-item-avatar-two-line, article, list-item-three-line, article, list-item-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col 
                cols="12"
                md="3"
                class="pb-0">
                <v-skeleton-loader
                  type="article, card, card, card">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="!process.load && Object.keys(detail).length > 0"
            class="pb-0">
            <v-row>
              <v-col 
                class="pb-0">
                <v-breadcrumbs 
                  :items="list.breadcrumbs" 
                  class="pa-0">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </template>
                </v-breadcrumbs>

                <v-card
                  flat
                  color="transparent">
                  <v-card-title
                    class="text-h5 font-weight-bold mb-1 px-0">
                    {{ detail.post_title }}
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 font-weight-light px-0">
                    {{ detail.post_cat_title }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col 
                cols="12"
                md="9"
                class="pb-0">
                <v-row>
                  <v-col 
                    cols="1"
                    class="py-0">
                    <div 
                      class="sosmed">
                      <v-avatar 
                        v-for="(item, index) in list.sosmed"
                        :key="index"
                        class="mb-2"
                        width="50"
                        height="50">
                        <ShareNetwork
                          :network="item.network"
                          :url="`https://sitr.klaten.go.id${$route.path}`"
                          :title="detail.post_title"
                          :description="detail.desc"
                          :quote="detail.desc"
                          hashtags="SITR,DPUPR,Kominfo,Klaten">
                          <v-img
                            width="45"
                            height="45"
                            contain
                            :src="item.icon">
                          </v-img>
                        </ShareNetwork>
                      </v-avatar>
                    </div>
                  </v-col>

                  <v-col 
                    cols="11"
                    class="py-0">
                    <v-card
                      flat
                      color="transparent"
                      class="mb-4"
                      style="border-radius: 15px;">
                      <v-img
                        width="100%"
                        height="500"
                        gradient="to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .5)"
                        :src="detail.post_image.length > 0 ? detail.post_image[selected.banner].url : require('@/assets/image/image_default.png')"
                        style="border-radius: 16px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <div 
                          class="d-flex justify-center"
                          style="position: absolute;
                                right: 0;
                                left: 0;
                                bottom: 30px;">
                          <v-btn 
                            v-for="(item, index) in detail.post_image" 
                            :key="index"
                            :min-width="selected.banner === index ? 50 : 13"
                            :width="selected.banner === index ? 50 : 13"
                            :height="13"
                            elevation="0"
                            x-small
                            :rounded="selected.banner === index"
                            :fab="selected.banner !== index"
                            :color="selected.banner === index ? '#2391d0' : '#FFFFFF'"
                            class="mx-2"
                            @click="selected.banner = index">
                          </v-btn>
                        </div>
                      </v-img>
                    </v-card>

                    <v-card
                      flat
                      class="pa-3"
                      style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                      <v-card-title 
                        class="font-weight-bold mb-1">
                        {{ detail.post_title }}
                      </v-card-title>

                      <v-card-subtitle 
                        class="font-weight-light pb-0 d-flex align-center">
                        <v-icon small>mdi-pound</v-icon>

                        <span 
                          class="ml-1">
                          {{ detail.post_cat_title }}
                        </span>
                      </v-card-subtitle>

                      <v-list 
                        color="transparent"
                        class="my-1">
                        <v-list-item>
                          <v-list-item-avatar
                            size="45">
                            <v-img
                              width="100%"
                              height="100%"
                              :src="detail.post_extra.createdby.image.length > 0 ? detail.post_extra.createdby.image : require('@/assets/image/member_default.jpg')"
                              style="border-radius: 10px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-1 font-weight-bold mb-1">
                              {{ detail.post_extra.createdby.name }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="caption font-weight-light">
                              {{ detail.post_create_datetime | date }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider 
                        class="mx-4"
                        style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />

                      <v-card-text
                        class="body-1 font-weight-light line-text-second black--text"
                        style="opacity: 60%;">
                        <div v-html="detail.post_content"></div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col 
                cols="12"
                md="3"
                class="pb-0">
                <div 
                  style="position: sticky; top: 90px;">
                  <v-divider
                    class="mb-2"
                    style="border-top: 10px solid #2391d0; border-radius: 50px; width: 40%;" />

                  <div 
                    class="title font-weight-bold mb-3">
                    Berita Terkait
                  </div>

                  <div v-if="list.related.length > 0">
                    <v-card
                      v-for="(item, index) in list.related"
                      :key="index"
                      :to="`/news/${item.post_slug}`"
                      color="white"
                      :class="index < 3 ? 'mb-4' : ''"
                      style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                      <v-img
                        width="100%"
                        height="200"
                        gradient="to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .9)"
                        :src="item.post_image.length > 0 ? item.post_image[0].url : require('@/assets/image/image_default.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-chip
                          small
                          class="ma-3 white--text"
                          color="#2391d0">
                          {{ item.post_cat_title}}
                        </v-chip>

                        <v-card-text
                          class="white--text"
                          style="position: absolute; bottom: 0px;">
                          <div class="title font-weigght-bold one-line">
                            {{ item.post_title }}
                          </div>
                          
                          <div 
                            class="body-2 font-weight-light two-line"
                            v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')">
                          </div>
                        </v-card-text>
                      </v-img>
                    </v-card>
                  </div>

                  <div v-else
                    class="font-italic font-weight-light line-text-second"
                    style="opacity: 60%;">
                    *Berita terkait belum tersedia, kunjungi berita lainnya

                    <nuxt-link to="/news">
                      disini
                    </nuxt-link>.
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          banner: 0
        },
        list: {
          breadcrumbs: [
            {
              text: 'Berita',
              disabled: false,
              href: '/news'
            },
            {
              text: 'Detail Berita',
              disabled: true,
              href: '#'
            }
          ],
          related: [],
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ]
        },
        detail: { },
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/news/:slug',
          title: 'Detail Berita',
          description: 'Detail Berita SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      async fetchData () {
        this.process.load = true

        let params = {
          slug: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data

            this.fetchNews()
          } 
        })
      },
      async fetchNews () {
        this.process.news = true

        let params = {
          limit: 4,
          post_status: 'publish',
          post_cat_id: this.detail.post_cat_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.news = false
            
            this.list.related = response.data
          } 
        })
      }
    }
  }
</script>