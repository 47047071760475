<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            min-height="65.1vh"
            color="transparent">
            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Tahapan <span class="orange--text">Penerbitan SITR</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                Pengajuan keterangan informasi rencana tata ruang diajukan secara online.
              </div>

              <v-row 
                class="mb-4"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>

              <v-container>
                <v-row
                  justify="center">
                  <v-col
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    cols="12"
                    md="3"
                    class="px-0 avatar_loader">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="avatar, article">
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="transparent">
                          <div
                            v-if="index < 3"
                            style="width: 70%;
                                  position: absolute;
                                  top: 55px;
                                  right: 0px;">
                            <v-divider
                              :style="selected > index ? 'border-top: 10px solid #fac68d;' : 'border-top: 10px solid #FFFFFF;'"
                              style="border-radius: 0px;" />
                          </div>

                          <div>
                            <v-avatar
                              @click="selected = index"
                              :color="selected >= index ? '#fac68d' : 'white'"
                              class="cursor-pointer"
                              size="120"
                              style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; z-index: 2;">
                              <v-img
                                contain
                                width="60"
                                height="60"
                                class="ma-auto"
                                alt="vuetify"
                                :src="item.cat_image">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                            
                            <div
                              class="text-center px-16 mt-6 mb-12 line-text-second"
                              :class="selected >= index ? 'black--text' : 'grey--text'">
                              {{ item.cat_title }}
                            </div>
                          </div>


                          <div
                            v-if="index > 0"
                            style="width: 70%;
                                  position: absolute;
                                  top: 55px;
                                  left: 0px;">
                            <v-divider
                              :style="selected >= index ? 'border-top: 10px solid #fac68d;' : 'border-top: 10px solid #FFFFFF;'"
                              style="border-radius: 0px;" />
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image, image"
                      style="border-radius: 0px;">
                      <div>
                        <v-card 
                          flat
                          color="transparent"
                          class="mb-16">
                          <!-- <v-card-text
                            class="mt-4 mb-10">
                            <div
                              class="text-h5 font-weight-bold line-text-second black--text mb-2">
                              {{ list[selected].cat_title }}
                            </div>

                            <div
                              class="title font-weight-light">
                              {{ list[selected].cat_content }}
                            </div>
                          </v-card-text> -->

                          <iframe 
                            style="border-radius: 50px;"
                            width="100%"
                            height="700"
                            src="https://www.youtube.com/embed/rUVViHc5Yys?autoplay=0" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                          </iframe>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected:  0,
        list: [],
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/guide',
          title: 'Panduan',
          description: 'Panduan SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          cat_type: 'tata_ruang',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      },
    }
  }
</script>