<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-skeleton-loader
            :loading="process.banner"
            type="image"
            class="banner_loader">
            <div>
              <div v-if="!process.banner && list.banner.length > 0">
                <v-img 
                  v-for="(item, index) in list.banner"
                  :key="index"
                  class="ma-auto"
                  height="100vh"
                  alt="vuetify"
                  gradient="to bottom, rgba(0, 62, 105, .6), rgba(0, 62, 105, .6)"
                  :src="item.image === '' ? require('@/assets/background/bg_banner_1.jpg') : item.image">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#2391d0">
                      </v-progress-circular>
                    </v-row>
                  </template>

                  <v-container>
                    <v-row
                      style="min-height: 100vh;"
                      align="center"
                      justify="center">
                      <v-col
                        cols="6">
                        <div
                          class="text-h4 white--text mb-6"
                          style="opacity: 60%;">
                          Selamat Datang Di Aplikasi,
                        </div>

                        <div
                          class="text-h2 font-weight-bold white--text line-text-zero">
                          {{ item.title}}
                        </div>

                        <div
                          class="title font-weight-light white--text mt-12 mb-16">
                          <span v-html="item.content">
                          </span>
                        </div>

                        <v-btn
                          :to="!token ? '/auth/login' : '/dashboard?menu=submission'"
                          rounded
                          x-large
                          elevation="0"
                          color="orange"
                          class="body-1 font-weight-bold text-capitalize white--text">
                          Ajukan Permohonan Sekarang ?
                        </v-btn>
                      </v-col>

                      <v-col
                        cols="6"
                        class="text-center pl-16">
                        <v-btn
                          fab
                          elevation="0"
                          width="100"
                          height="100"
                          class="ml-16">
                          <v-icon size="60" color="#2391d0">mdi-play</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </div>
            </div>
          </v-skeleton-loader>

          <!-- <v-card
            flat
            class="py-8 px-16"
            color="rgba(0, 62, 105, .25)"
            style="border-radius: 0px;">
            <v-row
              align="center"
              justify="center">
              <v-col
                cols="4">
                <div
                  class="text-h3 font-weight-bold color-default text-center mb-2">
                  150 ribu+
                </div>

                <div
                  class="title text-center">
                  Pengguna
                </div>
              </v-col>

              <v-col
                cols="4">
                <div
                  class="text-h3 font-weight-bold color-default text-center mb-2">
                  400 ribu+
                </div>

                <div
                  class="title text-center">
                  Ajuan
                </div>
              </v-col>

              <v-col
                cols="4">
                <div
                  class="text-h3 font-weight-bold color-default text-center mb-2">
                  370 ribu+
                </div>

                <div
                  class="title text-center">
                  Disetujui
                </div>
              </v-col>
            </v-row>
          </v-card> -->
        </section>

        <section>
          <div
            class="text-center my-16 py-16 pb-6">
            <div
              class="text-h4 font-weight-bold line-text-second">
              Aplikasi Untuk Kemudahan Mendapatkan
            </div>

            <div
              class="text-h4 font-weight-bold line-text-second orange--text">
              Keterangan Informasi Rencana Tata Ruang
            </div>

            <div
              class="title font-weight-light line-text-second mb-4"
              style="opacity: 60%;">
              Pengajuan keterangan informasi rencana tata ruang diajukan secara online.
            </div>

            <v-row 
              class="mb-12"
              justify="center">
              <v-col 
                cols="1">
                <v-divider 
                  style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
              </v-col>
            </v-row>

            <v-container>
              <v-row>
                <v-col
                  v-for="(item, index) in process.tata_ruang ? 4 : list.tata_ruang"
                  :key="index"
                  cols="12"
                  md="3"
                  class="avatar_loader">
                  <v-skeleton-loader
                    :loading="process.tata_ruang"
                    type="avatar, article">
                    <div>
                      <v-card v-if="!process.tata_ruang && list.tata_ruang.length > 0"
                        flat
                        color="transparent">
                        <v-avatar
                          color="white"
                          size="120"
                          style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;">
                          <v-img
                            contain
                            width="60"
                            height="60"
                            class="ma-auto"
                            alt="vuetify"
                            :src="item.cat_image">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <v-card-text
                          class="text-center black--text mt-4">
                          <div
                            class="body-1 font-weight-bold mb-4"
                            style="height: 50px;">
                            {{ item.cat_title }}
                          </div>

                          <div
                            class="body-2 line-text-second"
                            style="opacity: 60%;">
                            <span class="three-line"
                              v-html="item.cat_content.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>

            <v-container
              class="mt-16">
              <v-row
                align="center">
                <v-col
                  cols="12"
                  md="4">
                  <v-skeleton-loader
                    v-for="(item, index) in process.pola_ruang ? 3 : list.pola_ruang"
                    :key="index"
                    :loading="process.pola_ruang"
                    type="article">
                    <div>
                      <div v-if="!process.pola_ruang && list.pola_ruang && index < 3"
                        :class="index === 1 ? 'my-12' : ''"
                        class="text-right">
                        <div
                          class="body-1 font-weight-bold mb-2"
                          style="height: 50px">
                          <span class="orange--text">Kawasan</span>

                          <div>
                            {{ item.cat_title }}
                          </div>
                        </div>

                        <div
                          class="body-2 line-text-second"
                          style="opacity: 60%; height: 150px;">
                          <span 
                            v-html="item.cat_content.replace(/(<([^>]+)>)/ig, '')">
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>

                <v-col
                  cols="12"
                  md="4">
                  <v-btn
                    to="/map"
                    elevation="0"
                    rounded
                    x-large
                    color="#2391d0"
                    class="body-1 font-weight-bold text-capitalize white--text">
                    <v-icon>mdi-map-search-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      Peta Pola Ruang
                    </span>
                  </v-btn>
                  
                  <v-img
                    contain
                    width="100%"
                    height="200"
                    class="ma-auto"
                    alt="vuetify"
                    :src="require('@/assets/image/example/wireless.png')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#2391d0">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-img
                    width="300"
                    height="auto"
                    class="ma-auto"
                    alt="vuetify"
                    :src="require('@/assets/image/example/maping.png')"
                    style="position: relative; top: -60px; left: -5px; transform: rotate(2deg);">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#2391d0">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="12"
                  md="4">
                  <v-skeleton-loader
                    v-for="(item, index) in process.pola_ruang ? 3 : list.pola_ruang"
                    :key="index"
                    :loading="process.pola_ruang"
                    type="article">
                    <div>
                      <div v-if="!process.pola_ruang && list.pola_ruang && index > 2"
                        :class="index === 4 ? 'my-12' : ''"
                        class="text-left">
                        <div
                          class="body-1 font-weight-bold mb-2"
                          style="height: 50px">
                          <span class="orange--text">Kawasan</span>

                          <div>
                            {{ item.cat_title }}
                          </div>
                        </div>

                        <div
                          class="body-2 line-text-second"
                          style="opacity: 60%; height: 150px;">
                          <span 
                            v-html="item.cat_content.replace(/(<([^>]+)>)/ig, '')">
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </section>

        <section>
          <div
            class="text-center">
            <v-img
              width="100%"
              height="auto"
              class="ma-auto"
              alt="vuetify"
              :src="require('@/assets/background/header_section_1.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#2391d0">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card
              flat
              color="white"
              class="pb-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Layanan Yang <span class="orange--text">Kami Sediakan</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                SITR hanya diperuntukan bagi usaha dengan kategori Usaha Mikro atau Usaha Kecil (UMK).
              </div>

              <v-row 
                class="mb-12"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>

              <v-container
                class="pb-16">
                <v-row>
                  <v-col
                    v-for="(item, index) in process.service ? 8 : list.service"
                    :key="index"
                    cols="12"
                    md="3">
                    <v-skeleton-loader
                      :loading="process.service"
                      type="image, article">
                      <div>
                        <v-card v-if="!process.service && list.service.length > 0"
                          color="white"
                          class="pa-3 hover_service"
                          style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                          <v-card-text
                            class="text-left black--text">
                            <div
                              class="body-1 font-weight-bold mb-4 text_hover one-line">
                              {{ item.cat_title }}
                            </div>

                            <div
                              class="body-2 line-text-second text_hover"
                              style="opacity: 60%;">
                              <span class="three-line"
                                v-html="item.cat_content.replace(/(<([^>]+)>)/ig, '')">
                              </span>
                            </div>
                          </v-card-text>

                          <v-card-actions
                            class="d-flex align-end">
                            <!-- <div>
                              <v-btn
                                elevation="0"
                                rounded
                                small
                                color="#2391d0"
                                class="body-2 text-capitalize white--text btn_hover">
                                <v-icon small class="mr-1">mdi-information-outline</v-icon>
                                Detail
                              </v-btn>
                            </div> -->

                            <v-spacer />

                            <div>
                              <v-img
                                width="60"
                                height="60"
                                class="ma-auto"
                                alt="vuetify"
                                :src="item.cat_image">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </section>

        <section 
          class="parallax">
          <v-container>
            <v-row 
              align="center" 
              justify="center" 
              style="height: 500px;">
              <v-col>
                <div 
                  class="text-h4 font-weight-bold line-text-second white--text text-center">
                  <div>
                    Terima Kasih Telah mengunjungi Layanan Kami
                  </div>

                  <div>
                    SITR Online Klaten
                  </div>
                </div>

                <v-row
                  justify="center">
                  <v-col
                    cols="12" 
                    md="7">
                    <div 
                      class="text-center white--text">
                      <div 
                        class="title font-weight-light line-text-second mt-5 mb-11">
                        Nikmati layanan yang telah kami sediakan untuk kemudahan kebutuhan Anda, jika ada hal yang perlu di tanyakan silahkan hubungi kami dibawah ini.
                      </div>

                      <v-btn
                        href="https://api.whatsapp.com/send/?phone=6281390956104&text&type=phone_number&app_absent=0"
                        target="_blank"
                        rounded
                        x-large
                        elevation="0"
                        color="orange"
                        class="body-1 font-weight-bold text-capitalize white--text">
                        <v-icon class="mr-1">mdi-phone-in-talk-outline</v-icon>
                        Hubungi Kami Sekarang !
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-img
            class="ma-auto"
            alt="vuetify"
            :src="require('@/assets/background/header_section_5.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>

            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Informasi <span class="orange--text">Untuk Anda</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                Silahkan Kunjungi Link Berikut untuk Memperoleh Pelayanan Lainnya di Bidang Tata Ruang DPUPR Klaten.
              </div>

              <v-row 
                class="mb-12"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>

              <v-container>
                <v-row>
                  <v-col 
                    cols="12" 
                    md="3"
                    v-for="(item, index) in process.category ? 4 : list.category"
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.category"
                      type="image">
                      <div>
                        <v-card v-if="!process.category && list.category.length > 0"
                          @click="selected.category = item.id"
                          class="pa-3 d-flex align-center justify-center bg_group"
                          min-height="125"
                          color="#2391d0"
                          style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                          <v-card-text 
                            class="title font-weight-bold white--text line-text-second text-center">
                            <span class="two-line">
                              {{ item.cat_title }}
                            </span>
                          </v-card-text>

                          <v-avatar v-if="selected.category === item.id"
                            size="40"
                            style="border: 3px solid #f3f3f3; position: absolute; bottom: -20px; z-index: 2;">
                            <v-btn
                              fab
                              color="orange">
                              <v-icon color="white">mdi-chevron-down</v-icon>
                            </v-btn>
                          </v-avatar>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>

              <v-container>
                <v-row>
                  <v-col 
                    cols="12" 
                    md="3"
                    v-for="(item, index) in process.news ? 4 : list.news"
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.news"
                      type="image, article">
                      <div>
                        <v-card v-if="!process.news && list.news.length > 0"
                          :to="`/news/${item.post_slug}`"
                          color="white"
                          style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                          <v-img
                            width="100%"
                            height="200"
                            :src="item.post_image.length > 0 ? item.post_image[0].url : require('@/assets/image/image_default.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-chip
                              small
                              class="ma-3 white--text"
                              color="#2391d0"
                              style="position: absolute; left: 0;">
                              {{ item.post_cat_title }}
                            </v-chip>
                          </v-img>

                          <div 
                            class="text-center" 
                            style="position: relative;">
                            <div 
                              style="position: absolute; top: -25px; left: 0; right: 0;">
                              <v-avatar 
                                size="50" 
                                color="#FFF">
                                <v-img 
                                  width="100%"
                                  height="100%"
                                  style="border: 2px solid #FFF;"
                                  :src="item.post_extra.createdby.image === '' ? require('@/assets/image/member_default.jpg') : item.post_extra.createdby.image">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#2391d0">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>
                            </div>
                          </div>

                          <v-card-text 
                            class="text-center body-2 mt-5">
                            {{ item.post_extra.createdby.name }}
                          </v-card-text>

                          <v-card-text 
                            class="body-1 font-weight-bold black--text text-center py-0">
                            <span 
                              class="two-line" 
                              style="min-height: 50px;">
                              {{ item.post_title }}
                            </span>
                          </v-card-text>

                          <v-card-text 
                            class="text-center body-2 line-text-second black--text"
                            style="opacity: 60%; height: 105px;">
                            <span class="three-line"
                              v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </v-card-text>

                          <v-divider />

                          <v-card-text 
                            class="d-flex align-center">
                            <div 
                              class="d-flex align-center">
                              <v-icon size="18">mdi-eye-outline</v-icon>

                              <span 
                                class="caption ml-1">
                                19x Dilihat
                              </span>
                            </div>

                            <v-spacer />

                            <div 
                              class="caption text-right color-default">
                              {{ item.post_update_datetime | date }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>

              <div v-if="pagination.news.total_data > 10 && limit < pagination.news.total_data"
                class="mt-12">
                <v-btn
                  to="/news"
                  rounded
                  large
                  elevation="0"
                  color="#2391d0"
                  class="body-1 font-weight-bold white--text text-capitalize">
                  <span
                    class="mr-1">
                    Lihat Lainnya
                  </span>

                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-img>
        </section>

        <section>
          <v-container
            class="mt-16 pt-16">
            <v-row>
              <v-col>
                <v-card
                  flat
                  class="bg_group"
                  min-height="200"
                  color="#2391d0"
                  style="border-radius: 30px;">
                  <v-row>
                    <v-col
                      cols="4">
                      <v-img
                        width="100"
                        height="100"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_1.jpeg')"
                        style="border-radius: 20px; position: absolute; top: -15px; left: 20px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-img
                        width="130"
                        height="80"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_2.jpeg')"
                        style="border-radius: 20px; position: absolute; top: -50px; left: 140px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-img
                        width="100"
                        height="100"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_4.jpeg')"
                        style="border-radius: 20px; position: absolute; top: 50px; left: 250px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-img
                        width="100"
                        height="100"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_5.jpeg')"
                        style="border-radius: 20px; position: absolute; top: 120px; left: 40px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-img
                        width="140"
                        height="80"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_6.jpeg')"
                        style="border-radius: 20px; position: absolute; top: 170px; left: 160px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      
                      <v-img
                        width="180"
                        height="180"
                        class="ma-auto"
                        alt="vuetify"
                        gradient="to bottom, rgba(0, 62, 105, .3), rgba(0, 62, 105, .3)"
                        :src="require('@/assets/image/example/image_8.jpeg')"
                        style="border-radius: 30px; position: absolute; left: 100px; border: 3px solid #ffffff82;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="7"
                      class="py-6">
                      <div
                        class="font-weight-bold white--text line-text-second mb-2"
                        style="font-size: 1.6rem;">
                        Kegiatan Bidang Tata Ruang DPUPR Klaten
                      </div>

                      <div
                        class="font-weight-light white--text line-text-second"
                        style="font-size: 1.15rem; opacity: 80%;">
                        Bidang Tata Ruang DPUPR Kabupaten Klaten bekerja sama dengan Instansi untuk pengendalian, pemanfaatan dan mengoptimalkan kegiatan teknis di bidang pekerjaan umum dan penataan ruang.
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <!-- <section>
          <div
            class="text-center mt-16 pt-16">
            <div
              class="text-h4 font-weight-bold line-text-second">
              Apa <span class="orange--text">Kata Mereka ?</span>
            </div>

            <div
              class="title font-weight-light line-text-second mb-4"
              style="opacity: 60%;">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>

            <v-row 
              class="mb-12"
              justify="center">
              <v-col 
                cols="1">
                <v-divider 
                  style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
              </v-col>
            </v-row>

            <v-container>
              <v-row>
                <v-col
                  v-for="(item, index) in list.review"
                  :key="index"
                  cols="12"
                  md="3">
                  <v-card
                    color="white"
                    class="pa-3"
                    style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                    <v-img
                      contain
                      width="55"
                      height="55"
                      class="ma-4 mb-2"
                      alt="vuetify"
                      :src="require('@/assets/image/example/quote.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    
                    <v-card-text
                      class="text-left black--text pb-2">
                      <div
                        class="body-1 font-weight-bold mb-4">
                        {{ item.title }}
                      </div>

                      <div
                        class="body-2 line-text-second"
                        style="opacity: 60%;">
                        {{ item.desc }}
                      </div>
                    </v-card-text>

                    <v-card-text
                      class="text-left">
                      <v-avatar>
                        <v-img
                          alt="vuetify"
                          :src="item.image">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div
                        class="body-2 font-weight-bold black--text mt-3">
                        {{ item.name }}
                      </div>

                      <v-rating
                        v-model="item.rating"
                        :half-increments="false"
                        background-color="orange lighten-3"
                        color="orange"
                        size="14"
                        dense
                        hover>
                      </v-rating>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <div
              class="mt-12">
              <v-btn
                to="/review"
                rounded
                large
                elevation="0"
                color="#2391d0"
                class="body-1 font-weight-bold white--text text-capitalize">
                <span
                  class="mr-1">
                  Lihat Lainnya
                </span>

                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </section> -->

        <section>
          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_2.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>

            <!-- <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="7">
                  <div
                    class="pr-12">
                    <div
                      class="text-h4 font-weight-bold line-text-second">
                      Kontak <span class="orange--text">Kami</span>
                    </div>

                    <div
                      class="title font-weight-light line-text-second d-flex align-start mb-2"
                      style="opacity: 60%;">
                      <v-icon size="22" class="mt-2" color="black">mdi-home-map-marker</v-icon>

                      <span
                        class="ml-3">
                        Jl. Sulawesi No.26, Sikenong, Kabupaten, Kec. Klaten Tengah, Kabupaten Klaten, Jawa Tengah 57413
                      </span>
                    </div>

                    <div
                      class="title font-weight-light line-text-second d-flex align-start mb-2"
                      style="opacity: 60%;">
                      <v-icon size="22" class="mt-2" color="black">mdi-phone-in-talk-outline</v-icon>

                      <span
                        class="ml-3">
                        +6281332051369
                      </span>
                    </div>

                    <div
                      class="title font-weight-light line-text-second d-flex align-start"
                      style="opacity: 60%;">
                      <v-icon size="22" class="mt-2" color="black">mdi-email-outline</v-icon>

                      <span
                        class="ml-3">
                        johndoe@gmail.con
                      </span>
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  md="5">
                  <v-card
                    color="white"
                    class="pa-3"
                    style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                    <v-card-text
                      class="pb-2">
                      <ValidationObserver 
                        ref="form">
                        <v-form>
                          <ValidationProvider 
                            name="fullname" 
                            rules="required" 
                            v-slot="{ errors }">
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Nama Lengkap
                              <span class="error--text">
                                (wajib diisi)
                              </span>
                            </div>
                            
                            <v-text-field
                              v-on:keyup.enter="save"
                              filled
                              rounded
                              dense
                              color="#2391d0"
                              placeholder="Contoh. John Doe"

                              v-model="form.fullname"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>

                          <ValidationProvider 
                            name="phone" 
                            rules="required|min:8|max:13"
                            v-slot="{ errors }">
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Nomor Telepon
                              <span class="error--text">
                                (wajib diisi)
                              </span>
                            </div>
                            
                            <v-text-field
                              v-on:keyup.enter="save"
                              filled
                              rounded
                              dense
                              color="#2391d0"

                              type="number"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              min="0"

                              placeholder="Contoh. 0813XXXXXXXX"

                              v-model="form.phone"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>

                          <ValidationProvider 
                            name="email" 
                            rules="required|email" 
                            v-slot="{ errors }">
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Email
                              <span class="error--text">
                                (wajib diisi)
                              </span>
                            </div>
                            
                            <v-text-field
                              v-on:keyup.enter="save"
                              filled
                              rounded
                              dense
                              color="#2391d0"
                              placeholder="Contoh. XXXX@gmail.com"

                              v-model="form.email"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>

                          <ValidationProvider 
                            name="note" 
                            rules="required" 
                            v-slot="{ errors }">
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Keterangan
                              <span class="error--text">
                                (wajib diisi)
                              </span>
                            </div>

                            <v-textarea
                              v-on:keyup.enter="save"
                              style="border-radius: 20px;"
                              filled
                              rounded
                              dense
                              auto-grow
                              rows="4"
                              row-height="30"
                              shaped
                              
                              color="#2391d0"
                              placeholder="Contoh. Tuliskan keterangan disini"

                              v-model="form.note"
                              :error-messages="errors"
                              required>
                            </v-textarea>
                          </ValidationProvider>
                        </v-form>
                      </ValidationObserver>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        @click="save()"
                        block
                        rounded
                        large
                        elevation="0"
                        color="orange"
                        class="body-1 font-weight-bold text-capitalize white--text">
                        Kirim
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container> -->
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          category: null
        },
        list: {
          banner: [],
          tata_ruang: [],
          pola_ruang: [],
          service: [],
          category: [],
          news: [],
          review: [
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            },
            {
              name: 'John Doe',
              title: 'Lorem Ipsum is simply dummy text',
              desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              image: require('@/assets/image/example/member.png'),
              rating: 5
            }
          ]
        },
        pagination: {
          banner: {},
          tata_ruang: {},
          pola_ruang: {},
          service: {},
          category: {},
          news: {}
        },
        form: {
          fullname: '',
          phone: '',
          email: '',
          note: ''
        },
        process: {
          banner: false,
          tata_ruang: false,
          pola_ruang: false,
          service: false,
          category: false,
          news: false
        },
        // SEO
        content: {
          url: '/',
          title: 'Beranda',
          description: 'Beranda SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      'selected.category': function () {
        this.fetchNews()
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchBanner()
        this.fetchTataRuang()
        this.fetchPolaRuang()
        this.fetchService()
        this.fetchCategory()
        this.fetchNews()
      },
      async fetchBanner () {
        this.process.banner = true

        let params = {
          type: 'banner',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          this.process.banner = false

          if (response.code === 200) {
            this.list.banner = response.data
            this.pagination.banner = response.pagination
          } 
        })
      },
      async fetchTataRuang () {
        this.process.tata_ruang = true

        let params = {
          cat_type: 'tata_ruang',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.tata_ruang = false

          if (response.code === 200) {
            this.list.tata_ruang = response.data
            this.pagination.tata_ruang = response.pagination
          } 
        })
      },
      async fetchPolaRuang () {
        this.process.pola_ruang = true

        let params = {
          cat_type: 'pola_ruang',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.pola_ruang = false

          if (response.code === 200) {
            this.list.pola_ruang = response.data
            this.pagination.pola_ruang = response.pagination
          } 
        })
      },
      async fetchService () {
        this.process.service = true

        let params = {
          cat_type: 'service',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.service = false

          if (response.code === 200) {
            this.list.service = response.data
            this.pagination.service = response.pagination
          } 
        })
      },
      async fetchCategory () {
        this.process.category = true

        let params = {
          cat_type: 'news',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.category = false

          if (response.code === 200) {
            this.list.category = response.data
            this.pagination.category = response.pagination

            this.selected.category = this.list.category[0].id
          } 
        })
      },
      async fetchNews () {
        this.process.news = true

        let params = {
          post_cat_id: this.selected.category
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          this.process.news = false

          if (response.code === 200) {
            this.list.news = response.data
            this.pagination.news = response.pagination
          } 
        })
      },
      async save () {
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          // SAVE TO REST API
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style scoped>
  .hover_service:hover {
    background-color: #2391d0 !important;
    transition: 0.3s;
  }

  .hover_service:hover .text_hover {
    color: #FFFFFF !important;
    transition: 0.3s;
  }

  .hover_service:hover .btn_hover {
    background-color: orange !important;
    transition: 0.3s;
  }
</style>