<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="white"
            height="64"
            style="border-radius: 0px;">
          </v-card>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')"
            style="-moz-transform: scale(-1, -1);
                  -o-transform: scale(-1, -1);
                  -webkit-transform: scale(-1, -1);
                  transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-img
            contain
            width="100%"
            height="200"
            class="ma-auto"
            alt="vuetify"
            :src="require('@/assets/image/example/wireless.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          
          <v-container
            class="pt-0 pb-16 mb-16">
            <v-row 
              justify="center">
              <v-col 
                cols="12"
                md="10"
                class="pt-0 pb-16">
                <v-card
                  flat
                  color="white"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 50px;">
                  <v-row
                    align="center">
                    <v-col 
                      cols="7"
                      class="py-0 pr-0">
                      <v-img
                        width="100%"
                        height="124vh"
                        class="ma-auto"
                        gradient="to bottom, rgba(0, 62, 105, .1), rgba(0, 62, 105, .5)"
                        :src="require('@/assets/background/bg_banner_3.jpeg')"
                        style="border-radius: 50px 0px 0px 50px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <!-- <v-card
                        flat
                        class="d-flex align-center justify-center pa-16 pr-0"
                        min-height="125vh"
                        color="#cacaca"
                        style="border-radius: 50px 0px 0px 50px;">
                        <v-card-text
                          class="pr-0">
                          <v-img
                            contain
                            width="100%"
                            height="30vh"
                            class="ma-auto"
                            :src="require('@/assets/background/bg_banner_2.jpeg')"
                            style="border-radius: 50px 0px 0px 50px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card-text>
                      </v-card> -->
                    </v-col>

                    <v-col 
                      cols="5"
                      class="pr-6">
                      <v-card-text
                        class="mb-4">
                        <v-list 
                          dense 
                          color="transparent" 
                          class="py-0">
                          <v-list-item 
                            class="px-0">
                            <v-list-item-avatar 
                              tile
                              size="80" 
                              class="mt-0">
                              <v-img 
                                alt="kominfo" 
                                :src="require(`@/assets/image/time/${isDay}.png`)"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="white">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div 
                                class="text-h5 text-capitalize font-weight-bold black--text one-line mb-0">
                                Hai . . .
                              </div>
                              
                              <div 
                                class="body-1 font-weight-light black--text" 
                                style="opacity: 60%;">
                                Selamat {{ isDay }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-text>
                        <div class="title font-weight-bold">
                          Verifikasi
                        </div>

                        <div class="body-1 font-weight-light"
                          style="opacity: 60%;">
                         SITR Online Klaten
                        </div>
                      </v-card-text>
                      
                      <v-card-text>
                        <ValidationObserver 
                          ref="form">
                          <v-form>
                            <div
                              class="mb-6">
                              <div 
                                class="body-2 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                Gambar Profil
                                <span class="error--text">
                                  (wajib diisi)
                                </span>
                              </div>

                              <upload-folder v-model="upload.folder">
                                <div 
                                  slot="activator" 
                                  class="cursor-pointer">
                                  <v-card 
                                    flat
                                    width="175"
                                    height="175"
                                    class="d-flex align-center justify-center"
                                    style="border-radius: 30px;
                                          border: 2px solid #eaeaea; 
                                          border-style: dashed;">
                                    <v-img v-if="!process.image && form.image !== ''"
                                      alt="kominfo" 
                                      width="175"
                                      height="171"
                                      gradient="to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)"
                                      :src="form.image"
                                      class="ma-auto d-flex align-center text-center">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#2391d0">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>

                                      <v-icon 
                                        size="50" 
                                        color="white">
                                        mdi-cloud-upload-outline
                                      </v-icon>
                                    </v-img>

                                    <v-icon v-if="!process.image && form.image === ''"
                                      size="50" 
                                      color="grey">
                                      mdi-cloud-upload-outline
                                    </v-icon>

                                    <v-progress-circular v-if="process.image"
                                      indeterminate
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-card>
                                </div>
                              </upload-folder>
                            </div>
                            
                            <ValidationProvider 
                              name="fullname" 
                              rules="required" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Nama Lengkap
                                </span>
                              </div>
                              
                              <v-text-field
                                v-on:keyup.enter="save"
                                filled
                                rounded
                                dense 
                                color="#2391d0"

                                placeholder="Contoh. Firmansyah Abdul Rafi"

                                v-model="form.fullname"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>

                            <ValidationProvider 
                              name="kecamatan_code" 
                              rules="required" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Kecamatan
                                </span>
                              </div>

                              <v-autocomplete
                                filled
                                rounded
                                dense 
                                color="#2391d0"

                                placeholder="Contoh. Trucuk"

                                :search-input.sync="autocomplete.subdistrict"
                                :loading="process.subdistrict"
                                
                                :items="config.subdistrict"
                                item-value="kecamatan_code"
                                item-text="kecamatan_name"
                                item-color="#2391d0"

                                v-model="form.kecamatan_code"
                                :error-messages="errors"
                                required>
                              </v-autocomplete>
                            </ValidationProvider>

                            <ValidationProvider 
                              name="kelurahan_code" 
                              rules="required" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Kelurahan
                                </span>
                              </div>

                              <v-autocomplete
                                filled
                                rounded
                                dense 
                                color="#2391d0"

                                placeholder="Contoh. Kradenan"

                                :search-input.sync="autocomplete.village"
                                :loading="process.village"
                                
                                :items="config.village"
                                item-value="desa_code"
                                item-text="desa_name"
                                item-color="#2391d0"

                                v-model="form.kelurahan_code"
                                :error-messages="errors"
                                required>
                              </v-autocomplete>
                            </ValidationProvider>

                            <ValidationProvider 
                              name="dukuh" 
                              rules="required" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Dukuh
                                </span>
                              </div>

                              <v-text-field
                                v-on:keyup.enter="save"
                                filled
                                rounded
                                dense 
                                color="#2391d0"
                                
                                placeholder="Contoh. Kemiri"
                                
                                v-model="form.dukuh"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>

                            <v-row> 
                              <v-col
                                cols="6"
                                class="py-0">
                                <ValidationProvider 
                                  name="rt" 
                                  rules="required" 
                                  v-slot="{ errors }">
                                  <div 
                                    class="body-1 mb-2">
                                    <span 
                                      class="red--text">
                                      *
                                    </span>

                                    <span 
                                      style="opacity: 60%;">
                                      RT
                                    </span>
                                  </div>

                                  <v-text-field
                                    v-on:keyup.enter="save"
                                    filled
                                    rounded
                                    dense 
                                    color="#2391d0"

                                    type="number"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    min="0"
                                    
                                    placeholder="Contoh. 22"
                                    
                                    v-model="form.rt"
                                    :error-messages="errors"
                                    required>
                                  </v-text-field>
                                </ValidationProvider>
                              </v-col>

                              <v-col
                                cols="6"
                                class="py-0">
                                <ValidationProvider 
                                  name="rw" 
                                  rules="required" 
                                  v-slot="{ errors }">
                                  <div 
                                    class="body-1 mb-2">
                                    <span 
                                      class="red--text">
                                      *
                                    </span>

                                    <span 
                                      style="opacity: 60%;">
                                      RW
                                    </span>
                                  </div>

                                  <v-text-field
                                    v-on:keyup.enter="save"
                                    filled
                                    rounded
                                    dense 
                                    color="#2391d0"

                                    type="number"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    min="0"
                                    
                                    placeholder="Contoh. 10"
                                    
                                    v-model="form.rw"
                                    :error-messages="errors"
                                    required>
                                  </v-text-field>
                                </ValidationProvider>
                              </v-col>
                            </v-row>

                            <ValidationProvider 
                              name="password" 
                              rules="required|min:5|max:20" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Password
                                </span>
                              </div>

                              <v-text-field
                                v-on:keyup.enter="save"
                                filled
                                rounded
                                dense 
                                color="#2391d0"
                                
                                placeholder="Contoh. ******"
                                :type="show ? 'text' : 'password'"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show = !show"
                                :counter="20"
                                
                                v-model="form.password"
                                :error-messages="errors"
                                required
                                class="mb-5">
                              </v-text-field>
                            </ValidationProvider>

                            <div v-if="message.error.length > 0"
                              class="body-3 red--text mb-4">
                              <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                              {{ message.error }}
                            </div>

                            <v-btn
                              :loading="process.form"
                              :disabled="process.form"
                              v-on:keyup.enter="save"
                              @click="save"
                              rounded
                              large
                              block
                              color="orange"
                              elevation="0"
                              class="body-1 font-weight-bold white--text text-capitalize">
                              Verifikasi
                            </v-btn>
                          </v-form>
                        </ValidationObserver>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        upload: {
          folder: null
        },
        show: false,
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        config: {
          subdistrict: [],
          village: []
        },
        form: {
          token: '',
          fullname: '',
          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: '',
          image: '',
          password: ''
        },
        process: {
          load: false,
          form: false,
          subdistrict: false,
          village: false,
          image: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/verification/:slug',
          title: 'Verifikasi',
          description: 'Verifikasi SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Camera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE
              this.form.image = this.upload.folder.data.full.file_url
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      isDay() {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'

        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          token: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/auth/register/verify_data`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            if (Object.keys(response.data).length > 0) {
              this.form = response.data.rvd_userdata
  
              this.form.token = response.data.rvd_token
  
              this.form.password = ''
            } else {
              this.message.error = 'Token Expired'
            }
          }
        })
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register/verify_data`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.$router.push({ path: '/auth/success' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>