<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.upload"
          @keydown.esc="dialog.upload = false"
          width="500"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              Upload Lampiran

              <v-spacer/>

              <v-icon 
                @click="dialog.upload = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="d-flex align-center mt-4">
              <div
                class="text-center">
                <upload-folder v-model="upload.folder">
                  <div 
                    slot="activator" 
                    class="cursor-pointer">
                    <v-btn
                      fab
                      color="#f0f0f0"
                      width="100"
                      height="100"
                      elevation="0">
                      <v-img
                        width="70"
                        height="70"
                        contain
                        alt="kominfo"
                        :src="require('@/assets/icon/upload_image.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-btn>

                    <div
                      class="text-center font-weight-bold black--text mt-2">
                      Gambar
                    </div>
                  </div>
                </upload-folder>
              </div>

              <v-spacer />

              <div 
                class="d-flex align-center"
                style="width: 150px;">
                <v-divider 
                  style="border: 2px solid #eaeaea; border-radius: 50px;" />

                <div 
                  class="mx-2 font-weight-bold"
                  style="opacity: 80%;">
                  Atau
                </div>

                <v-divider 
                  style="border: 2px solid #eaeaea; border-radius: 50px;" />
              </div>

              <v-spacer />

              <div
                class="text-center">
                <upload-document v-model="upload.document">
                  <div 
                    slot="activator" 
                    class="cursor-pointer">
                    <v-btn
                      fab
                      color="#f0f0f0"
                      width="100"
                      height="100"
                      elevation="0">
                      <v-img
                        width="60"
                        height="60"
                        contain
                        alt="kominfo"
                        :src="require('@/assets/icon/upload_folder.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-btn>

                    <div
                      class="text-center font-weight-bold black--text mt-2">
                      PDF
                    </div>
                  </div>
                </upload-document>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card v-if="!sheet.form"
            flat
            color="transparent">
            <div
              class="d-flex align-center mb-4">
              <v-btn
                @click="sheet.form = true; reset();"
                large
                rounded
                elevation="0"
                color="#2391d0"
                class="body-1 font-weight-bold text-capitalize white--text">
                <v-icon>mdi-format-list-group-plus</v-icon>
                
                <span
                  class="ml-1">
                  Tambah Ajuan
                </span>
              </v-btn>

              <v-spacer />

              <v-text-field
                style="max-width: 50%;"
                filled
                rounded
                dense
                single-line
                color="#2391d0"

                hide-details

                placeholder="Cari data yang Anda butuhkan . . ."

                clearable
                
                v-model="search"
                v-on:keyup.enter="fetch()"
                @click:clear="search = ''; fetch();">
              </v-text-field>
            </div>

            <v-row>
              <v-col
                v-for="(item, index) in process.load ? 5 : list"
                :key="index"
                cols="12"
                class="py-0 pb-4">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <div>
                      <v-list 
                        flat 
                        three-line
                        class="py-0">
                        <v-list-item
                          :to="`/submission/${item.id}`">
                          <v-list-item-avatar 
                            tile 
                            size="75" 
                            class="mr-4">
                            <v-img 
                              style="border-radius: 10px;"
                              src="https://margondes.com/wp-content/uploads/2014/12/DesaJurangjero.png">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title 
                              class="body-1 text-capitalize font-weight-bold mb-1">
                              {{ item.ajuan_nama_badan_usaha }}
                            </v-list-item-title>

                            <div
                              class="black--text text-capitalize">
                              <div
                                class="caption mb-2">
                                NIB - {{ item.ajuan_nib_no }} | ALamat. {{ item.ajuan_nib_alamat }}
                              </div>

                              <v-divider/>

                              <div
                                style="opacity: 60%;">
                                <div
                                  class="body-2 line-text-first my-2">
                                  - Nomor Registrasi. {{ item.ajuan_no_reg }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Luas. {{ item.ajuan_luas }} M<sup>2</sup>
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Kegiatan Usaha. {{ item.ajuan_kbli_judul }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Lokasi Usaha. {{ item.ajuan_lokasi_usaha }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Alamat. {{ item.ajuan_dukuh }}, RT{{ item.ajuan_rt }} / RW{{ item.ajuan_rw }}, Des. {{ item.ajuan_kelurahan_name }}, Kec. {{ item.ajuan_kecamatan_name }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Keterangan. {{ item.ajuan_keterangan === '' ? '-' : item.ajuan_keterangan }}
                                </div>
                              </div>

                              <div>
                                <div
                                  class="body-2 line-text-first my-2">
                                  <span
                                    style="opacity: 60%;">
                                    - Status.
                                  </span>

                                  <span
                                    :class="item.ajuan_status === 'MENUNGGU VERIFIKASI' ? 'orange--text' :
                                          item.ajuan_status === 'DIPROSES' ? 'blue--text' :
                                          item.ajuan_status === 'DITOLAK' ? 'red--text' :
                                          item.ajuan_status === 'DIKOREKSI' ? 'purple--text' :
                                          item.ajuan_status === 'DISETUJUI' ? 'green--text' :
                                          item.ajuan_status === 'DIHAPUS' ? 'red--text' : ''">
                                    {{ item.ajuan_status }}
                                  </span>
                                </div>
                              </div>

                              <v-divider/>

                              <div
                                class="caption mt-2">
                                Tanggal Pengajuan {{ item.ajuan_create_datetime | date }} Pukul {{ item.ajuan_create_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>

              <v-col v-if="!process.load && pagination.total_page < 1">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <Empty
                    size="150"
                    message="Riwayat Ajuan" />
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-card v-else
            color="white"
            class="box-shadow border-radius pa-3">
            <v-card-title 
              class="body-1 font-weight-bold pt-3 mb-1">
              Surat Keterangan Informasi Tata Ruang
            </v-card-title>

            <v-card-subtitle
              class="body-2">
              Silahkan mengisi form ajuan usaha dibawah ini:
            </v-card-subtitle>
            
            <v-divider class="mx-4 mb-2"/>

            <v-card-text 
              class="pt-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="nama_badan_usaha" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Badan Usaha
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 60%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Ternak Ikan"
                      
                      v-model="form.nama_badan_usaha"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="nib_no" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nomor NIB
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 40%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      placeholder="Contoh. 0206220065189"
                      
                      v-model="form.nib_no"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="nib_alamat" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Alamat NIB
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Jl. Pemuda No. 27"
                      
                      v-model="form.nib_alamat"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kbli" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kegiatan Usaha
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save()"
                      style="max-width: 60%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Peternakan Ikan"

                      :search-input.sync="autocomplete.kbli"
                      :loading="process.kbli"

                      clearable
                      @click:clear="form.kbli = ''"
                      
                      :items="config.kbli"
                      item-value="kbli_kbli"
                      item-text="kbli_judul"
                      item-color="#2391d0"

                      :no-filter="true"

                      v-model="form.kbli"
                      :error-messages="errors"
                      required>
                      <!-- <template slot="item" slot-scope="{ item }">
                        {{ item.kbli_judul }}
                      </template>

                      <template slot="selection" slot-scope="{ item }">
                        {{ item.kbli_judul }}
                      </template> -->
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="lokasi_usaha" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Lokasi Usaha
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Kradenan, Trucuk"
                      
                      v-model="form.lokasi_usaha"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kecamatan_code" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Kecamatan
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save()"
                      style="max-width: 50%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"

                      placeholder="Contoh. Trucuk"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="config.subdistrict"
                      item-value="kecamatan_code"
                      item-text="kecamatan_name"
                      item-color="#2391d0"

                      v-model="form.kecamatan_code"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kelurahan_code" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Kelurahan
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save()"
                      style="max-width: 50%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"

                      placeholder="Contoh. Kradenan"

                      :search-input.sync="autocomplete.village"
                      :loading="process.village"
                      
                      :items="config.village"
                      item-value="desa_code"
                      item-text="desa_name"
                      item-color="#2391d0"

                      v-model="form.kelurahan_code"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="dukuh" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Dukuh
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Jl. Pemuda No. 01"
                      
                      v-model="form.dukuh"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="py-0">
                      <ValidationProvider 
                        name="rt" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          RT
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save()"
                          filled
                          rounded
                          dense
                          single-line
                          color="#2391d0"

                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          placeholder="Contoh. 22"
                          
                          v-model="form.rt"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      class="py-0">
                      <ValidationProvider 
                        name="rw" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          RW
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save()"
                          filled
                          rounded
                          dense
                          single-line
                          color="#2391d0"

                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          placeholder="Contoh. 10"
                          
                          v-model="form.rw"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <ValidationProvider 
                    name="luas" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Luas
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 30%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      placeholder="Contoh. 200"
                      
                      v-model="form.luas"
                      :error-messages="errors"
                      required>
                      <template v-slot:append>
                        <div
                          class="black--text my-1"
                          style="opacity: 40%;">
                          M<sup>2</sup>
                        </div>
                      </template>
                    </v-text-field>
                  </ValidationProvider>

                  <div>
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Polygon Map
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-card
                      flat
                      min-height="70vh"
                      color="transparent"
                      class="mb-6"
                      style="border-radius: 15px;">
                      <div 
                        id="map" 
                        style="width: 100%; height: 70vh;">
                      </div>
                    </v-card>

                    <div
                      class="mb-6">
                      <div>
                        #Jika ingin menambahkan koordinat secara manual, silahkan klik tombol <strong>Tambah Koordinat</strong>.
                      </div>
                      
                      <v-btn
                        @click="addLatlng()"
                        rounded
                        color="#333333"
                        elevation="0"
                        class="text-capitalize white--text my-4">
                        Tambah Koordinat
                      </v-btn>

                      <v-card v-if="form.koordinat.length > 0"
                        flat
                        color="#f0f0f033"
                        style="border-radius: 15px; border: 3px solid #f0f0f0;">
                        <v-card-text>
                          <v-row
                            v-for="(item, index) in form.koordinat"
                            :key="index"
                            align="center">
                            <v-col
                              cols="12"
                              md="5"
                              class="py-0">
                              <ValidationProvider 
                                name="lat" 
                                rules="required" 
                                v-slot="{ errors }">
                                <div class="body-2 mb-2">
                                  <span class="red--text">*</span>
                                  Latitude <strong>{{ index + 1 }}</strong>
                                  <span class="error--text">
                                    (wajib diisi)
                                  </span>
                                </div>

                                <v-text-field
                                  v-on:keyup.enter="save()"
                                  type="number"   
                                  filled
                                  rounded
                                  dense
                                  single-line
                                  color="#2391d0"

                                  placeholder="Contoh. -7.7126396311"

                                  @blur="initLatlng()"
                                  
                                  v-model.number="item.lat"
                                  :error-messages="errors"
                                  required>
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>

                            <v-col
                              cols="12"
                              md="5"
                              class="py-0">
                              <ValidationProvider 
                                name="lng" 
                                rules="required" 
                                v-slot="{ errors }">
                                <div class="body-2 mb-2">
                                  <span class="red--text">*</span>
                                  Longitude <strong>{{ index + 1 }}</strong>
                                  <span class="error--text">
                                    (wajib diisi)
                                  </span>
                                </div>

                                <v-text-field
                                  v-on:keyup.enter="save()"
                                  type="number"   
                                  filled
                                  rounded
                                  dense
                                  single-line
                                  color="#2391d0"

                                  placeholder="Contoh. 110.5909243135"

                                  @blur="initLatlng()"
                                  
                                  v-model.number="item.lng"
                                  :error-messages="errors"
                                  required>
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>

                            <v-col
                              cols="12"
                              md="2"
                              class="text-center py-0">
                              <v-btn
                                @click="removeLatlng(index)"
                                rounded
                                color="red"
                                elevation="0"
                                class="body-2 white--text text-capitalize d-flex align-center">
                                <v-icon size="20" color="white">mdi-delete-empty</v-icon>

                                <span
                                  class="ml-1">
                                  Hapus
                                </span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>

                  <div>
                    <div class="body-1 font-weight-bold black--text mb-2">
                      Lampiran
                    </div>

                    <v-card
                      v-for="(item, index) in form.lampiran"
                      :key="index"
                      flat
                      color="transparent"
                      class="mb-6">
                      <div
                        class="body-2 d-flex align-start pb-2">
                        <span v-if="item.require" class="red--text ml-0 ma-1" >*</span>

                        <span
                          class="line-text-second"
                          style="opacity: 60%;"
                          v-html="item.name">
                        </span>

                        <span v-if="item.require"
                          class="error--text ml-1"
                          style="width: 175px; margin: 2px;">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-divider 
                        style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />

                      <div
                        class="mt-4">
                        <v-row>
                          <v-col 
                            v-for="(item_img, index_img) in item.file"
                            :key="index_img"
                            cols="6" 
                            md="4">
                            <v-card 
                              flat
                              width="100%"
                              height="175"
                              class="d-flex align-center justify-center"
                              style="border-radius: 15px;
                                    border: 2px solid #eaeaea; 
                                    border-style: dashed;
                                    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                              <!-- <v-img 
                                alt="kominfo" 
                                width="100%"
                                height="175"
                                :src="item_img.url"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <div @click="item.file.splice(index_img, 1)"
                                  class="cursor-pointer"
                                  style="position: absolute; top: 5px; right: 5px;">
                                  <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                </div>
                              </v-img> -->

                              <v-img v-if="item_img.type === 'image'"
                                alt="kominfo" 
                                width="100%"
                                height="175"
                                :src="item_img.url"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <div @click="item.file.splice(index_img, 1)"
                                  class="cursor-pointer"
                                  style="position: absolute; top: 5px; right: 5px;">
                                  <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                </div>
                                
                                <div 
                                  class="font-weight-bold color-default"
                                  style="position: absolute; top: 5px; left: 5px;">
                                  Gambar
                                </div>
                              </v-img>
                              
                              <div v-if="item_img.type === 'document'"
                                class="d-flex align-center justify-center">
                                <iframe 
                                  :src="`${item_img.url}#toolbar=0`" 
                                  allowfullscreen
                                  frameborder="0" 
                                  width="100%"
                                  height="175"
                                  style="border-radius: 15px; border: 2px solid #323639;">
                                </iframe>

                                <div @click="item.file.splice(index_img, 1)"
                                  class="cursor-pointer"
                                  style="position: absolute; top: 5px; right: 5px;">
                                  <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                </div>

                                <div 
                                  class="font-weight-bold color-default"
                                  style="position: absolute; top: 5px; left: 5px;">
                                  PDF
                                </div>
                              </div>
                            </v-card>
                          </v-col>

                          <v-col 
                            cols="6"
                            md="4">
                            <!-- <upload-folder v-model="upload.folder">
                              <div 
                                slot="activator" 
                                class="cursor-pointer">
                                <v-card @click="index_file = index"
                                  flat
                                  width="100%"
                                  height="175"
                                  class="d-flex align-center justify-center"
                                  style="border-radius: 30px;
                                        border: 2px solid #eaeaea; 
                                        border-style: dashed;">
                                  <v-icon v-if="!process.file || index_file !== index"
                                    size="50" 
                                    color="grey">
                                    mdi-cloud-upload-outline
                                  </v-icon>

                                  <v-progress-circular v-if="process.file && index_file === index"
                                    indeterminate
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-card>
                              </div>
                            </upload-folder> -->

                            <v-card @click="dialog.upload = true; index_file = index;"
                              flat
                              width="100%"
                              height="175"
                              class="d-flex align-center justify-center"
                              style="border-radius: 30px;
                                    border: 2px solid #eaeaea; 
                                    border-style: dashed;">
                              <v-icon v-if="(!process.file && !process.doc) || index_file !== index"
                                size="50" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>

                              <v-progress-circular v-if="(process.file || process.doc) && index_file === index"
                                indeterminate
                                color="#2391d0">
                              </v-progress-circular>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </div>

                  <div>
                    <div class="body-2 mb-2">
                      Keterangan
                      <span>
                        (bisa dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"
                      rows="5"

                      placeholder="Contoh. Tulis keterangan disini . . ."

                      v-model="form.keterangan">
                    </v-textarea>
                  </div>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="save()"
                @click="save()"
                color="#6dbc45"
                elevation="0"
                rounded
                class="body-2 font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Simpan
              </v-btn>

              <v-btn
                :disabled="process.form"
                @click="sheet.form = false; reset();"
                outlined
                rounded
                class="body-2 font-weight-bold text-capitalize red--text">
                <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          upload: false
        },
        sheet: {
          form: false
        },
        autocomplete: {
          kbli: '',
          subdistrict: '',
          village: ''
        },
        config: {
          kbli: [],
          lampiran: [],
          subdistrict: [],
          village: []
        },

        search: '',

        list: [],
        pagination: {},
        
        // MAPING
        map: null,

        upload: {
          folder: null,
          document: null
        },
        index_file: '',
        form: {
          nama_badan_usaha: '',
          nib_no: '',
          nib_alamat: '',
          kbli: '',
          lokasi_usaha: '',
          luas: '',
          keterangan: '',
          lampiran: [],
          koordinat: [],
          extra: {},

          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: ''
        },
        process: {
          load: false,
          form: false,
          kbli: false,
          lampiran: false,
          file: false,
          doc: false,
          subdistrict: false,
          village: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      UploadDocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'autocomplete.kbli': function() {
        this.static().kbli()
      },
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.dialog.upload = false

            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.form.lampiran[this.index_file].file.push({
                url: this.upload.folder.data.full.file_url,
                type: this.upload.folder.type,
                process: this.upload.folder.process,
                user_id: this.user.id,
                file_id: this.upload.folder.id === undefined ? 0 : this.upload.folder.id
              })
            }
          }
        },
        deep: true
      },
      'upload.document': {
        handler: function () {
          if (this.upload.document) {
            this.dialog.upload = false

            this.process.doc = this.upload.document.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD PDF
              this.form.lampiran[this.index_file].file.push({
                url: this.upload.document.data.file_url,
                type: this.upload.document.type,
                process: this.upload.document.process,
                user_id: this.user.id,
                file_id: this.upload.document.id === undefined ? 0 : this.upload.document.id
              })
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          search: this.search,
          ajuan_status: 'MENUNGGU VERIFIKASI'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/history`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination

            this.static().lampiran()
          } 
        })
      },
      static () {
        let t = this
        return {
          async kbli () {
            t.process.kbli = true

            let params = {
              search: t.autocomplete.kbli
            }

            await t.$axios.$get(`${process.env.PREFIX_PROXY}/master/kbli`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.kbli = false

                t.config.kbli = response.data
              }
            })
          },
          async lampiran () {
            t.process.lampiran = true

            await t.$axios.$get(`${process.env.PREFIX_PROXY}/master/data_lampiran`)
            .then(response => {
              if (response.code == 200) {
                t.process.lampiran = false

                t.config.lampiran = response.data
              }
            })
          },
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          nama_badan_usaha: '',
          nib_no: '',
          nib_alamat: '',
          kbli: '',
          lokasi_usaha: '',
          luas: '',
          keterangan: '',
          lampiran: [],
          koordinat: [],
          extra: {},

          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: ''
        }

        if (this.config.lampiran.length > 0) {
          this.config.lampiran.map(obj => {
            this.form.lampiran.push({
              id: obj.id,
              name: obj.mdl_title,
              type: obj.mdl_type,
              require: obj.mdl_is_required,
              file: []
            })
          })
        }

        this.process.form = false

        this.message.error = ''

        this.$emit('goToTop', false)

        this.initMap()
      },
      initMap () {
        let self = this

        this.$gmapApiPromiseLazy().then(() => {
          self.map = new google.maps.Map(document.getElementById('map'), {
            center: { 
              lat: -7.71210962,
              lng: 110.59174574
            },
            zoom: 18,
            noClear: true,
            mapTypeId: 'hybrid', //satellite
            navigationControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            tilt: 0
          })

          self.map.data.addListener('addfeature', self.featureAdded)

          const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_LEFT,
              drawingModes: ['polygon']
            },
            polygonOptions: {
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              strokeColor: '#FF0000',
              strokeWeight: 2,
              strokeOpacity: 1,
              draggable: true,
              editable: true,
              clickable: true,
              zIndex: 1
            }
          })

          drawingManager.setMap(self.map)

          drawingManager.addListener('polygoncomplete', (polygon) => {
            if (polygon.getPath().getLength() < 3) {
              console.log('Polygons must have 3 or more points.')
              polygon.getPath().clear()
            } else {
              self.addFeature('Polygon', polygon.getPath())
              polygon.setMap(null)
            }
          })
        })
      },
      featureAdded (e) {
        switch (e.feature.getGeometry().getType()) {
          case 'Polygon':
            addFeature('Polygon', e.feature.getGeometry().getAt(0).getArray())
            break
        }

        self.map.data.remove(e.feature)
      },
      addFeature (type, path) {
        let self = this

        switch (type) {
          case 'Polygon':
            let polygon = new google.maps.Polygon({
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              strokeColor: '#FF0000',
              strokeWeight: 2,
              strokeOpacity: 1,
              draggable: true,
              editable: true,
              clickable: true,
              zIndex: 1
            })

            polygon.setPath(path)

            // polygon.getPath().addListener('insert_at', (e) => {
            //   console.log(e)
            // })

            polygon.getPath().addListener('set_at', (e, obj) => {
              // self.form.koordinat[e].lat = obj.lat()
              // self.form.koordinat[e].lng = obj.lng()

              let polypath = polygon.getPath()
              self.form.koordinat = []

              for (var i = 0; i < polypath.length; i++) {
                self.form.koordinat.push({
                  lat: polypath.getAt(i).lat(),
                  lng: polypath.getAt(i).lng()
                })
              }
            })

            // polygon.getPath().addListener('remove_at', (e) => {
            //   console.log(e)
            // })

            polygon.addListener('dragend', (e) => {
              let polypath = polygon.getPath()
              self.form.koordinat = []
              let bounds = new google.maps.LatLngBounds()

              for (var i = 0; i < polypath.length; i++) {
                bounds.extend(polypath.getAt(i))

                self.form.koordinat.push({
                  lat: polypath.getAt(i).lat(),
                  lng: polypath.getAt(i).lng()
                })
              }

              // let center = bounds.getCenter()
              // stringlocation = JSON.stringify(self.form.koordinat)
              // stringcenter = JSON.stringify(center)
              // console.log(stringcenter + stringlocation)
            })

            polygon.addListener('rightclick', (e) => {
              if (e.vertex == undefined) return

              if (polygon.getPath().getLength() === 3) {
                polygon.setMap(null)
                self.form.koordinat = []
              } else {
                polygon.getPath().removeAt(e.vertex)
                self.form.koordinat.splice(e.vertex, 1)
              }
            })

            let data_polygon = polygon.getPath()

            for (let i = 0; i < data_polygon.getLength(); i++) {
              self.form.koordinat.push({
                lat: parseFloat(data_polygon.getAt(i).toUrlValue(10).split(',')[0]),
                lng: parseFloat(data_polygon.getAt(i).toUrlValue(10).split(',')[1])
              })

              const lats = self.form.koordinat.map(o => o.lat)
              self.form.koordinat = self.form.koordinat.filter(({lat}, index) => !lats.includes(lat, index + 1))
            }

            polygon.setMap(self.map)
            break
        }
      },

      addLatlng () {
        this.form.koordinat.push({
          lat: null,
          lng: null
        })
      },
      removeLatlng (index) {
        this.form.koordinat.splice(index, 1)

        this.initLatlng()
      },
      initLatlng () {
        let self = this

        if (this.form.koordinat.length > 2) {
          if (this.form.koordinat[2].lat !== '' && this.form.koordinat[2].lat !== null && this.form.koordinat[2].lng !== '' && this.form.koordinat[2].lng !== null) {
            this.$gmapApiPromiseLazy().then(() => {
              self.map = new google.maps.Map(document.getElementById('map'), {
                center: { 
                  lat: this.form.koordinat.length > 0 ? this.form.koordinat[0].lat : -7.71210962,
                  lng: this.form.koordinat.length > 0 ? this.form.koordinat[0].lng : 110.59174574
                },
                zoom: 18,
                noClear: true,
                mapTypeId: 'hybrid', //satellite
                navigationControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                tilt: 0
              })
    
              self.map.data.addListener('addfeature', self.featureAdded)
    
              const drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: this.form.koordinat.length < 1 ? google.maps.drawing.OverlayType.POLYGON : null,
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT,
                  drawingModes: ['polygon']
                },
                polygonOptions: {
                  fillColor: '#FF0000',
                  fillOpacity: 0.35,
                  strokeColor: '#FF0000',
                  strokeWeight: 2,
                  strokeOpacity: 1,
                  draggable: true,
                  editable: true,
                  clickable: true,
                  zIndex: 1
                }
              })
    
              drawingManager.setMap(self.map)
    
              drawingManager.addListener('polygoncomplete', (polygon) => {
                if (polygon.getPath().getLength() < 3) {
                  console.log('Polygons must have 3 or more points.')
                  polygon.getPath().clear()
                } else {
                  self.addFeature('Polygon', polygon.getPath())
                  polygon.setMap(null)
                }
              })
    
              if (this.form.koordinat.length > 0) self.addFeature('Polygon', this.form.koordinat)
            })
          }
        } else {
          this.initMap()
        }
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/create`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.sheet.form = false

              this.reset()

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>