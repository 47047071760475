<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <div
              class="d-flex align-center mb-4">
              <v-spacer />

              <v-text-field
                style="max-width: 50%;"
                filled
                rounded
                dense
                single-line
                color="#2391d0"

                hide-details

                placeholder="Cari data yang Anda butuhkan . . ."

                clearable
                
                v-model="search"
                v-on:keyup.enter="fetch()"
                @click:clear="search = ''">
              </v-text-field>
            </div>
            
            <v-row>
              <v-col
                v-for="(item, index) in process.load ? 7 : list"
                :key="index"
                cols="12"
                class="py-0 pb-4">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="list-item-three-line">
                    <div>
                      <v-list v-if="!process.load && list.length > 0"
                        flat 
                        three-line
                        class="py-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title 
                              class="body-1 text-capitalize font-weight-bold">
                              {{ item.announcement_title }}
                            </v-list-item-title>

                            <div
                              class="black--text text-capitalize">
                              <div
                                class="body-2 line-text-first my-2"
                                style="opacity: 60%;">
                                <span
                                  v-html="item.announcement_content.replace(/(<([^>]+)>)/ig, '')">
                                </span>
                              </div>

                              <div
                                class="caption">
                                {{ item.announcement_create_datetime | datetime }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>

              <v-col v-if="!process.load && pagination.total_page < 1">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <Empty
                    size="150"
                    message="Pengumuman" />
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        search: '',
        list: [],
        pagination: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/announcement`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>