<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            min-height="65.1vh"
            color="transparent">
            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Informasi <span class="orange--text">Untuk Anda</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                Silahkan Kunjungi Link Berikut untuk Memperoleh Pelayanan Lainnya di Bidang Tata Ruang DPUPR Klaten.
              </div>

              <v-row 
                class="mb-4"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>
              
              <v-container>
                <v-row 
                  justify="center"
                  class="mb-6">
                  <v-col
                    cols="12" 
                    md="4" 
                    sm="4" 
                    :class="rps === 'xs' ? '' : 'pr-0'">
                    <v-menu
                      rounded
                      offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :class="rps === 'xs' ? 'b_a br_a' : 'b_a br_l'"
                          elevation="0"
                          color="#f0f0f0"
                          block
                          height="40"
                          class="body-2 text-capitalize pr-2 d-flex justify-start"
                          v-bind="attrs"
                          v-on="on">
                          {{ filter.category_text }}
                          <v-icon 
                            color="grey darken-1" 
                            style="position: absolute; right: 0px;">
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list 
                        flat 
                        dense>
                        <v-list-item-group
                          color="#2391d0">
                          <v-list-item class="hover-list"
                            v-for="(item, index) in list.category"
                            :key="index"
                            @click="
                              filter.category = item.cat_id; 
                              filter.category_text = item.cat_title;
                              fetchNews();">
                            <v-list-item-avatar 
                              size="35" 
                              color="white" 
                              class="my-0 mr-2" 
                              style="border: 2px solid #fff;">
                              <v-img 
                                contain
                                alt="vuetify"
                                :src="item.cat_image">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-title class="body-2">
                              {{ item.cat_title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-col>

                  <v-col 
                    cols="12" 
                    md="8" 
                    sm="8" 
                    :class="rps === 'xs' ? '' : 'pl-0'">
                    <div 
                      class="d-flex align-center justify-end" 
                      style="width: 100%;">
                      <v-text-field
                        :class="rps === 'xs' ? 'br_a' : 'br_r search-style'"
                        outlined
                        dense 
                        single-line
                        hide-details
                        color="#dadada"
                        placeholder="Temukan berita yang sesuai kebutuhan Anda . . ."
                        clearable
                        v-model="filter.search"
                        v-on:keyup.enter="fetchNews()"
                        @click:clear="filter.search = ''">
                      </v-text-field>

                      <v-btn
                        style="position: absolute; right: auto; border-radius: 0px 50px 50px 0px;"
                        elevation="0"
                        rounded
                        height="40"
                        color="#2391d0"
                        class="text-capitalize white--text">
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mb-6">
                  <v-col 
                    cols="12" 
                    md="3"
                    v-for="(item, index) in process.news ? 12 : list.news"
                    :key="index">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.news"
                      type="card, article">
                      <div>
                        <v-card v-if="!process.news && list.news.length > 0"
                          :to="`/news/${item.post_slug}`"
                          color="white"
                          style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                          <v-img
                            width="100%"
                            height="200"
                            :src="item.post_image.length > 0 ? item.post_image[0].url : require('@/assets/image/image_default.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-chip
                              small
                              class="ma-3 white--text"
                              color="#2391d0"
                              style="position: absolute; left: 0;">
                              {{ item.post_cat_title }}
                            </v-chip>
                          </v-img>

                          <div 
                            class="text-center" 
                            style="position: relative;">
                            <div 
                              style="position: absolute; top: -25px; left: 0; right: 0;">
                              <v-avatar 
                                size="50" 
                                color="#FFF">
                                <v-img 
                                  width="100%"
                                  height="100%"
                                  style="border: 2px solid #FFF;"
                                  :src="item.post_extra.createdby.image.length > 0 ? item.post_extra.createdby.image : require('@/assets/image/member_default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#2391d0">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>
                            </div>
                          </div>

                          <v-card-text 
                            class="text-center body-2 mt-5">
                            {{ item.post_extra.createdby.name }}
                          </v-card-text>

                          <v-card-text 
                            class="body-1 font-weight-bold black--text text-center py-0">
                            <span 
                              class="two-line" 
                              style="min-height: 50px;">
                              {{ item.post_title }}
                            </span>
                          </v-card-text>

                          <v-card-text 
                            class="text-center body-2 line-text-second black--text"
                            style="opacity: 60%; height: 105px;">
                            <span class="three-line"
                              v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </v-card-text>

                          <v-divider />

                          <v-card-text 
                            class="d-flex align-center">
                            <div 
                              class="d-flex align-center">
                              <v-icon size="18">mdi-eye-outline</v-icon>

                              <span 
                                class="caption ml-1">
                                19x Dilihat
                              </span>
                            </div>

                            <v-spacer />

                            <div 
                              class="caption text-right color-default">
                              {{ item.post_update_datetime | date }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <Empty v-if="!process.load && pagination.total_page < 1"
                  margin="my-16"
                  size="200"
                  message="Berita" />

                <Pagination v-if="pagination.total_page > 1"
                  class="my-6"
                  @changePage="fetchNews($event)" 
                  @reloadPage="fetchNews($event)"
                  :model="pagination" />
              </v-container>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          category_text: 'Semua Kategori',
          category: '',
          search: ''
        },
        list: {
          category: [],
          news: []
        },
        limit: 20,
        pagination: {},
        process: {
          category: false,
          news: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/news',
          title: 'Berita',
          description: 'Berita SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchCategory()
        this.fetchNews()
      },
      async fetchCategory () {
        this.process.category = true

        let params = {
          sort: 'DESC',
          cat_type: 'news'
        }

        this.list.category = [{
          cat_id: '',
          cat_title: 'Semua Kategori',
          cat_image: require('@/assets/image/all.png')
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.category = false

            response.data.map(obj => {
              this.list.category.push({
                cat_id: obj.cat_id,
                cat_title: obj.cat_title,
                cat_image: obj.cat_image
              })
            })
          } 
        })
      },
      async fetchNews (pagination = { row: 20, page: 1 }) {
        this.process.news = true

        this.limit = pagination.row

        let params = {
          limit: pagination.row,
          page: pagination.page,
          post_status: 'publish',
          search: this.filter.search,
          post_cat_id: this.filter.category
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.news = false
            
            this.list.news = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>

<style scoped>
  .search-style.v-text-field--outlined >>> fieldset {
    border-color: #dadada;
    border-left: 0px;
  }
</style>