<template>
  <div>
    <v-app-bar 
      app
      fixed
      :class="$route.path === '/' ? 'header' : ''"
      :color="$route.path !== '/' ? 'white' : ''"
      elevation="0">
      <v-container>
        <v-row>
          <v-col class="d-flex align-center">
            <nuxt-link 
              to="/">
              <div class="d-flex align-center">
                <div v-if="$route.path === '/' || $route.name === 'index'"
                  class="favicon_header">
                  <!-- FAVICON -->
                </div>

                <v-img v-else
                  center
                  height="50"
                  width="50"
                  contain
                  :src="require('@/assets/logo/favicon.png')">
                </v-img>

                <div 
                  class="title font-weight-bold ml-2"
                  :class="$route.path === '/' ? 'menu-text' : 'black--text'">
                  SITR Online Klaten
                </div>
              </div>
            </nuxt-link>

            <v-spacer/>

            <nuxt-link 
              v-for="(item, index) in menu"
              :key="index"
              :to="item.link">
              <v-card-title 
                class="subtitle-1 cursor-pointer d-flex justify-center"
                style="position: relative;">
                <span 
                  :class="$route.path === '/' || $route.name === 'index' ? 'menu-text' : 'black--text'"
                  :style="$route.path !== '/' || $route.name !== 'index' ? 'color: #000000 !important; opacity: 60%;' : ''">
                  {{ item.text }}
                </span>

                <v-divider v-if="item.link === $route.path"
                  :style="$route.path === '/' || $route.name === 'index' ? 'border: 2px solid #FFFFFF;' : 'border: 2px solid #2391d0;'"
                  style="border: 2px solid #FFFFFF;
                        border-radius: 50px;
                        position: absolute;
                        width: 45px;
                        bottom: 10px;" />
              </v-card-title>
            </nuxt-link>

            <v-divider
              class="mx-2 my-4 menu-divider"
              inset
              vertical
              style="height: 50%;">
            </v-divider>

            <nuxt-link
              to="/auth/login">
              <v-card-title 
                class="subtitle-1 cursor-pointer pl-3 d-flex justify-center"
                style="position: relative;">
                <span 
                  class="d-flex align-center"
                  :class="$route.path === '/' ? 'white--text' : 'red--text'">
                  <v-icon :color="$route.name === 'index' ? 'white' : 'red'">mdi-account-outline</v-icon>
                  
                  <span
                    class="ml-2">
                    Masuk atau Daftar
                  </span>
                </span>

                <v-divider v-if="$route.path === '/auth/login' || $route.path === '/auth/register'"
                  style="border: 2px solid red;
                        border-radius: 50px;
                        position: absolute;
                        width: 45px;
                        bottom: 10px;" />
              </v-card-title>
            </nuxt-link>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selected: {
          menu: 0
        },
        menu: [
          {
            text: "Beranda",
            link: "/"
          },
          {
            text: "Map",
            link: "/map"
          },
          {
            text: "Panduan",
            link: "/guide"
          },
          {
            text: "Layanan",
            link: "/service"
          },
          {
            text: "Berita",
            link: "/news"
          }
        ]
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()
    },
    methods: {
      
    }
  }
</script>

<style>
  .favicon_header {
    background-image: url('@/assets/logo/favicon.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;

    width: 50px;
    height: 50px;
  }
  
  .header {
    background-color: transparent !important;
  }
  .menu-text {
    color: #FFFFFF;
  }
  .menu-icon {
    color: #FFFFFF !important;
  }
  .menu-divider {
    background: #FFFFFF !important;
  }

  html:not([data-scroll="0"]) .favicon_header {
    background-image: url('@/assets/logo/favicon_white.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;

    width: 50px;
    height: 50px;
  }
  html:not([data-scroll="0"]) .header {
    /* background-color: #2391d0 !important; */
    /* background-color: rgba(0, 62, 105, .5) !important; */
    /* background-color: rgba(10, 32, 57, 1) !important; */

    background-color: rgba(35, 145, 208, 1) !important;
  }
  html:not([data-scroll="0"]) .menu-text {
    color: #FFFFFF !important;
  }
  html:not([data-scroll="0"]) .menu-icon {
    color: #FFFFFF !important;
  }
  html:not([data-scroll="0"]) .menu-divider {
    color: #000000 !important;
  }
  /* html:not([data-scroll="0"]) .profile_menu {
    background: #F3F3F3 !important;
    border-radius: 5px;
  } */

  .v-menu__content {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }
</style>