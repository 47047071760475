<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog 
          v-model="dialog"
          @keydown.esc="dialog = false"
          width="600"
          persistent
          scrollable>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              Ubah General

              <v-spacer/>

              <v-icon 
                @click="dialog = false; reset();"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>

            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="pt-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <div
                    class="mb-6">
                    <div 
                      class="body-2 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      Gambar Profil
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <upload-folder v-model="upload.folder">
                      <div 
                        slot="activator" 
                        class="cursor-pointer">
                        <v-card 
                          flat
                          width="175"
                          height="175"
                          class="d-flex align-center justify-center"
                          style="border-radius: 30px;
                                border: 2px solid #eaeaea; 
                                border-style: dashed;">
                          <v-img v-if="!process.image && form.image !== ''"
                            alt="kominfo" 
                            width="175"
                            height="171"
                            gradient="to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)"
                            :src="form.image"
                            class="ma-auto d-flex align-center text-center">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-icon 
                              size="50" 
                              color="white">
                              mdi-cloud-upload-outline
                            </v-icon>
                          </v-img>

                          <v-icon v-if="!process.image && form.image === ''"
                            size="50" 
                            color="grey">
                            mdi-cloud-upload-outline
                          </v-icon>

                          <v-progress-circular v-if="process.image"
                            indeterminate
                            color="#2391d0">
                          </v-progress-circular>
                        </v-card>
                      </div>
                    </upload-folder>
                  </div>
                  
                  <ValidationProvider 
                    name="fullname" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Lengkap
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. Firmansyah Abdul Rafi"
                      
                      v-model="form.fullname"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                  
                  <ValidationProvider 
                    name="email" 
                    rules="required|email" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Email
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 60%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      placeholder="Contoh. johndoe@gmail.com"
                      
                      v-model="form.email"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="phone" 
                    rules="required|min:8|max:13" 
                    v-slot="{ errors }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Telepon
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 50%;"
                      filled
                      rounded
                      dense
                      single-line
                      color="#2391d0"

                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      
                      placeholder="Contoh. 081332XXXXXX"
                      
                      v-model="form.phone"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kecamatan_code" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Kecamatan
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save()"
                      style="max-width: 60%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"

                      placeholder="Contoh. Trucuk"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="config.subdistrict"
                      item-value="kecamatan_code"
                      item-text="kecamatan_name"
                      item-color="#2391d0"

                      v-model="form.kecamatan_code"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kelurahan_code" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Kelurahan
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save()"
                      style="max-width: 60%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"

                      placeholder="Contoh. Kradenan"

                      :search-input.sync="autocomplete.village"
                      :loading="process.village"
                      
                      :items="config.village"
                      item-value="desa_code"
                      item-text="desa_name"
                      item-color="#2391d0"

                      v-model="form.kelurahan_code"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="dukuh" 
                    rules="required" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Dukuh
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 70%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"
                      
                      placeholder="Contoh. Kemiri"
                      
                      v-model="form.dukuh"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <v-row> 
                    <v-col
                      cols="4"
                      class="py-0">
                      <ValidationProvider 
                        name="rt" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div 
                          class="body-1 mb-2">
                          <span 
                            class="red--text">
                            *
                          </span>

                          <span 
                            style="opacity: 60%;">
                            RT
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense 
                          color="#2391d0"

                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          min="0"
                          
                          placeholder="Contoh. 22"
                          
                          v-model="form.rt"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      cols="4"
                      class="py-0">
                      <ValidationProvider 
                        name="rw" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div 
                          class="body-1 mb-2">
                          <span 
                            class="red--text">
                            *
                          </span>

                          <span 
                            style="opacity: 60%;">
                            RW
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense 
                          color="#2391d0"

                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          min="0"
                          
                          placeholder="Contoh. 10"
                          
                          v-model="form.rw"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="save()"
                @click="save()"
                color="#6dbc45"
                elevation="0"
                rounded
                class="body-2 font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Simpan
              </v-btn>

              <v-btn
                :disabled="process.form"
                @click="dialog = false; reset();"
                outlined
                rounded
                class="body-2 font-weight-bold text-capitalize red--text">
                <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            color="white"
            class="box-shadow border-radius pa-3" >
            <v-card-text>
              <v-chip
                class="font-weight-bold white--text border-radius px-4"
                color="#848484">
                NIK - {{ detail.nik }}
              </v-chip>

              <div
                class="text-h5 font-weight-bold black--text line-text-second my-2">
                {{ detail.fullname }}
              </div>

              <div
                class="d-flex align-center mb-1">
                <v-icon size="18">mdi-email-outline</v-icon>
                
                <span
                  class="ml-2">
                  {{ detail.email }}
                </span>
              </div>

              <div
                class="d-flex align-center mb-6">
                <v-icon size="18">mdi-phone-in-talk-outline</v-icon>
                
                <span
                  class="ml-2">
                  {{ detail.phone === '' ? '-' : detail.phone }}
                </span>
              </div>

              <div
                class="body-2 text-capitalize d-flex align-center mb-6">
                <div
                  class="orange--text font-weight-bold">
                  {{ detail.level }}
                </div>

                <div
                  class="mx-2">
                  |
                </div>

                <div
                  class="orange--text font-weight-bold">
                  {{ detail.jabatan === '' ? '-' : detail.jabatan }}
                </div>
              </div>

              <div
                class="body-1 font-weight-bold black--text mb-2">
                Identitas.
              </div>

              <div
                class="mb-6">
                NIK. {{ detail.nik }}
              </div>

              <!-- <div
                class="mb-6">
                KK. {{ detail.kk === '' ? '-' : detail.kk }}
              </div> -->

              <div
                class="body-1 font-weight-bold black--text mb-2">
                Alamat.
              </div>

              <div
                class="mb-6">
                {{ detail.dukuh === '' ? '-' : detail.dukuh }} {{ detail.rt === '' ? '-' : detail.rt }}/{{ detail.rw === '' ? '-' : detail.rw }}, {{ detail.kelurahan_name === '' ? '-' : detail.kelurahan_name }}, {{ detail.kecamatan_name === '' ? '-' : detail.kecamatan_name }}
              </div>

              <div
                class="mb-2 color-green">
                Terdaftar Tanggal <span class="font-weight-bold">{{ detail.create_datetime | datetime }}</span>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="dialog = true; reset();"
                elevation="0"
                large
                rounded
                style="background: #2196f333;"
                class="body-1 text-capitalize font-weight-bold d-flex align-center blue--text px-4 mb-2">
                <v-icon size="20">mdi-circle-edit-outline</v-icon>

                <span class="ml-1">
                  Ubah General
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: false,
        upload: {
          folder: null
        },
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        config: {
          subdistrict: [],
          village: []
        },
        form: {
          nik: '',
          fullname: '',
          email: '',
          phone: '',
          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: '',
          image: '',
          extra: {}
        },
        process: {
          form: false,
          subdistrict: false,
          village: false,
          image: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Camera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE
              this.form.image = this.upload.folder.data.full.file_url
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          nik: this.detail.nik,
          fullname: this.detail.fullname,
          email: this.detail.email,
          phone: this.detail.phone,
          kecamatan_code: this.detail.kecamatan_code,
          kecamatan_name: this.detail.kecamatan_name,
          kelurahan_code: this.detail.kelurahan_code,
          kelurahan_name: this.detail.kelurahan_name,
          dukuh: this.detail.dukuh,
          rt: this.detail.rt,
          rw: this.detail.rw,
          image: this.detail.image,
          extra: this.detail.extra
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
           await this.$axios.$post(`${process.env.PREFIX_PROXY}/profile/update`, this.form)
          .then((response) => {
            this.process.form = false

            if (response.code === 200) {
              this.dialog = false

              this.reset()

              this.$emit('load', false)
            } else {
              this.message.error = response.message
            }

          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>