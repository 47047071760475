<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="white"
            height="64"
            style="border-radius: 0px;">
          </v-card>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')"
            style="-moz-transform: scale(-1, -1);
                  -o-transform: scale(-1, -1);
                  -webkit-transform: scale(-1, -1);
                  transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-img
            contain
            width="100%"
            height="200"
            class="ma-auto"
            alt="vuetify"
            :src="require('@/assets/image/example/wireless.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-container
            class="pt-0 pb-16 mb-16">
            <v-row 
              justify="center">
              <v-col 
                cols="12"
                md="5"
                class="pt-0 pb-16">
                <v-card
                  flat
                  color="white"
                  class="pa-8"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 50px;">
                  <v-card-text>
                    <v-img 
                      width="150"
                      alt="kominfo" 
                      :src="require('@/assets/logo/favicon.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="white">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card-text>

                  <v-card-text 
                    class="text-h5 font-weight-bold d-flex align-center justify-center black--text mt-6 pb-0"
                    style="opacity: 60%;">
                    <v-icon color="#000">mdi-ray-end</v-icon>
                    
                    <span class="mx-2">
                      SITR Online Klaten
                    </span>

                    <v-icon color="#000">mdi-ray-start</v-icon>
                  </v-card-text>

                  <v-card-text
                    class="body-1 font-weight-light d-flex align-center justify-center black--text py-2 mb-4"
                    style="opacity: 60%;">
                    (DPUPR Kabupaten Klaten)
                  </v-card-text>

                  <v-card-text
                    class="text-center">
                    <div
                      class="body-1 font-weight-light line-text-second mb-12">
                      Terima kasih Anda telah melakukan verifikasi data akun yang Anda daftarkan, mohon untuk menunggu approval dari admin kami.
                    </div>

                    <v-btn
                      to="/auth/login"
                      rounded
                      large
                      block
                      color="#2391d0"
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize">
                      Masuk Sekarang
                    </v-btn>

                    <div class="d-flex align-center ma-6 mx-12">
                      <v-divider/>

                      <span class="body-1 font-weight-bold mx-3">
                        Atau
                      </span>

                      <v-divider/>
                    </div>

                    <v-btn
                      to="/auth/register"
                      outlined
                      rounded
                      large
                      block
                      color="#2391d0"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize color-default">
                      Daftar Sekarang
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        // SEO
        content: {
          url: '/auth/success',
          title: 'Verifikasi Terkirim',
          description: 'Verifikasi Terkirim SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>