var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('section',[_c('v-card',{staticClass:"box-shadow border-radius",attrs:{"color":"white"}},[_c('v-card-text',[_c('div',{staticClass:"title font-weight-bold black--text mb-1"},[_vm._v("\n              Log Status Ajuan\n            ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v("\n                Berikut adalah prubahan log status ajuan izin usaha.\n              ")])])]),_vm._v(" "),_c('div',[_c('v-avatar',{staticClass:"mx-8",attrs:{"size":"30","color":"#2391d0"}},[_c('v-icon',{attrs:{"color":"#FFFFFF"}},[_vm._v("mdi-progress-download")])],1),_vm._v(" "),_c('v-timeline',{attrs:{"align-center":"","dense":""}},[_vm._l((_vm.process.load ? 5 : _vm.list),function(item,index){return _c('v-timeline-item',{key:index,attrs:{"small":"","color":item.log_status === 'MENUNGGU VERIFIKASI' ? 'orange' :
                        item.log_status === 'DIPROSES' ? 'blue' :
                        item.log_status === 'DITOLAK' ? 'red' :
                        item.log_status === 'DIKOREKSI' ? 'purple' :
                        item.log_status === 'DISETUJUI' ? 'green' : 'red'}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.process.load,"type":"article"}},[_c('div',[(!_vm.process.load && _vm.list.length > 0)?_c('v-row',{staticClass:"cursor-pointer"},[_c('v-col',{attrs:{"cols":"3"}},[_c('strong',{staticClass:"black--text",staticStyle:{"opacity":"60%"}},[_vm._v("\n                          "+_vm._s(_vm._f("time")(item.log_create_datetime))+"\n                        ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"9"}},[_c('strong',{class:item.log_status === 'MENUNGGU VERIFIKASI' ? 'orange--text' :
                                  item.log_status === 'DIPROSES' ? 'blue--text' :
                                  item.log_status === 'DITOLAK' ? 'red--text' :
                                  item.log_status === 'DIKOREKSI' ? 'purple--text' :
                                  item.log_status === 'DISETUJUI' ? 'green--text' : 'red--text'},[_vm._v("\n                          "+_vm._s(item.log_status)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"body-2 font-weight-light line-text-second",staticStyle:{"opacity":"60%"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.log_note.replace(/(<([^>]+)>)/ig, ''))}})]),_vm._v(" "),_c('div',{staticClass:"caption font-weight-bold black--text",staticStyle:{"opacity":"60%"}},[_vm._v("\n                          "+_vm._s(_vm._f("date")(item.log_create_datetime))+"\n                        ")])])],1):_vm._e()],1)])],1)}),_vm._v(" "),(!_vm.process.load && _vm.list.length < 1)?_c('v-timeline-item',{attrs:{"small":"","color":"red"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Empty',{attrs:{"size":"150","message":"Log Status"}})],1)],1)],1):_vm._e()],2)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }