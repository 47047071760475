<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            min-height="65.1vh"
            color="transparent">
            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Layanan Yang <span class="orange--text">Kami Sediakan</span>
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                SITR hanya diperuntukan bagi usaha dengan kategori Usaha Mikro atau Usaha Kecil (UMK).
              </div>

              <v-row 
                class="mb-4"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>

              <v-container>
                <v-row
                  class="mb-6">
                  <v-col
                    v-for="(item, index) in process.load ? 8 : list"
                    :key="index"
                    cols="12"
                    md="3">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image, article">
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          color="white"
                          class="pa-3 hover_service"
                          style="box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; border-radius: 15px;">
                          <v-card-text
                            class="text-left black--text">
                            <div
                              class="body-1 font-weight-bold mb-4 text_hover one-line">
                              {{ item.cat_title }}
                            </div>

                            <div
                              class="body-2 line-text-second text_hover"
                              style="opacity: 60%;">
                              <span class="three-line"
                                v-html="item.cat_content.replace(/(<([^>]+)>)/ig, '')">
                              </span>
                            </div>
                          </v-card-text>

                          <v-card-actions
                            class="d-flex align-end">
                            <!-- <div>
                              <v-btn
                                elevation="0"
                                rounded
                                small
                                color="#2391d0"
                                class="body-2 text-capitalize white--text btn_hover">
                                <v-icon small class="mr-1">mdi-information-outline</v-icon>
                                Detail
                              </v-btn>
                            </div> -->

                            <v-spacer />

                            <div>
                              <v-img
                                width="60"
                                height="60"
                                class="ma-auto"
                                alt="vuetify"
                                :src="item.cat_image !== '' ? item.cat_image : require('@/assets/logo/favicon.png')">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <Empty v-if="!process.load && pagination.total_page < 1"
                  margin="my-16"
                  size="200"
                  message="Layanan" />

                <Pagination v-if="pagination.total_page > 1"
                  class="my-6"
                  @changePage="fetch($event)" 
                  @reloadPage="fetch($event)"
                  :model="pagination" />
              </v-container>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [],
        limit: 20,
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/service',
          title: 'Layanan',
          description: 'Layanan SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Pagination: () => import('@/components/Pagination')
    },
    watch: {

    },
    computed: {
      
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (pagination = { row: 20, page: 1 }) {
        this.process.load = true

        this.limit = pagination.row

        let params = {
          limit: pagination.row,
          page: pagination.page,
          cat_type: 'service',
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      },
    }
  }
</script>

<style scoped>
  .hover_service:hover {
    background-color: #2391d0 !important;
    transition: 0.3s;
  }

  .hover_service:hover .text_hover {
    color: #FFFFFF !important;
    transition: 0.3s;
  }

  .hover_service:hover .btn_hover {
    background-color: orange !important;
    transition: 0.3s;
  }
</style>