<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            min-height="65.1vh"
            color="transparent">
            <div
              class="text-center mt-16 pt-16">
              <div
                class="text-h4 font-weight-bold line-text-second">
                Peta <span class="orange--text">Pola Ruang</span>
              </div>

              <div
                class="title font-weight-light line-text-second"
                style="opacity: 60%;">
                Peta yang berisi informasi rencana penggunaan lahan
              </div>

              <div
                class="title font-weight-light line-text-second mb-4"
                style="opacity: 60%;">
                berdasarkan RTRW Klaten Tahun 2021-2041.
              </div>

              <v-row 
                class="mb-4"
                justify="center">
                <v-col 
                  cols="1">
                  <v-divider 
                    style="border-top: 10px solid #2391d0; border-radius: 50px;"/>
                </v-col>
              </v-row>

              <v-container>
                <v-row>
                  <v-col>
                    <v-card
                      flat
                      min-height="70vh"
                      color="transparent"
                      class="mb-16"
                      style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                      <div 
                        id="map_layer" 
                        style="width: 100%; height: 70vh;">
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // MAPING
        map: null,

        point: null,

        // SEO
        content: {
          url: '/map',
          title: 'Peta Pola Ruang',
          description: 'Peta Pola Ruang SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      
    },
    mounted () {
      this.initMap()
    },
    methods: {
      initMap () {
        let self = this

        this.$gmapApiPromiseLazy().then(() => {
          self.map = new google.maps.Map(document.getElementById('map_layer'), {
            center: { 
              lat: -7.673,
              lng: 110.619
            },
            zoom: 11.5,
            noClear: true,
            mapTypeId: 'hybrid', //satellite
            navigationControl: true,
            mapTypeControl: true,
            streetViewControl: true,
            tilt: 0
          })

          let getTileUrl = function(coordinates, zoom) {
            return (
              'https://sitaru.klaten.go.id/geoserver/wms?' +
              '&REQUEST=GetMap&SERVICE=WMS&VERSION=1.1.1' +
              '&LAYERS=rencana_pola_ruang_view' +
              '&FORMAT=image%2Fpng' +
              '&SRS=EPSG:3857&WIDTH=256&HEIGHT=256' +
              '&TRANSPARENT=true' +
              '&BBOX=' + self.xyzToBounds(coordinates.x, coordinates.y, zoom).join(',')
            )
          }

          let landcover = new google.maps.ImageMapType({
            getTileUrl: getTileUrl,
            name: 'Landcover',
            alt: 'National Land Cover Database 2023',
            tileSize: new window.google.maps.Size(256, 256),
            isPng: true,
            minZoom: 0,
            maxZoom: 24,
            opacity: 0.50
          })

          console.log(landcover)
          
          // self.map.overlayMapTypes.push(landcover)

          self.map.overlayMapTypes.setAt('0', landcover)

          google.maps.event.addListener(self.map, 'click', function (event) {  
            console.log(event)
          })
        })
      },

      xyzToBounds (x, y, z) {
        let EXTENT = [-Math.PI * 6378137, Math.PI * 6378137]

        let tileSize = (EXTENT[1] * 2) / Math.pow(2, z)
        let minx = EXTENT[0] + x * tileSize
        let maxx = EXTENT[0] + (x + 1) * tileSize

        // remember y origin starts at top
        let miny = EXTENT[1] - (y + 1) * tileSize
        let maxy = EXTENT[1] - y * tileSize

        return [minx, miny, maxx, maxy]
      }
    }
  }
</script>