<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            color="white"
            class="box-shadow border-radius">
            <v-card-text>
              <div
                class="title font-weight-bold black--text mb-1">
                #{{ detail.ajuan_no_reg }}
              </div>

              <div
                class="d-flex align-center"
                :class="message.error.length > 0 ? 'mb-2' : 'mb-4'">
                <div>
                  Ubah data ajuan Anda, dengan klik tombol <strong>Ubah Data</strong>.
                </div>

                <v-spacer />

                <div v-if="!sheet.form">
                  <v-btn
                    @click="sheet.form = true; reset()"
                    small
                    rounded
                    elevation="0"
                    color="orange"
                    class="text-capitalize white--text">
                    Ubah Data
                  </v-btn>
                </div>

                <div v-else>
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save()"
                    @click="save()"
                    small
                    rounded
                    elevation="0"
                    color="#6dbc45"
                    class="body-2 font-weight-bold text-capitalize white--text">
                    <v-icon small class="mr-1">mdi-check-bold</v-icon>
                    Simpan
                  </v-btn>

                  <v-btn
                    :disabled="process.form"
                    @click="sheet.form = false; reset();"
                    small
                    rounded
                    outlined
                    class="body-2 font-weight-bold text-capitalize red--text">
                    <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                    Batal
                  </v-btn>
                </div>
              </div>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>

              <div v-if="!sheet.form">
                <div
                  class="mb-4">
                  Status Ajuan

                  <v-icon small>mdi-chevron-double-right</v-icon>

                  <span
                    :class="detail.ajuan_status === 'MENUNGGU VERIFIKASI' ? 'orange--text' :
                          detail.ajuan_status === 'DIPROSES' ? 'blue--text' :
                          detail.ajuan_status === 'DITOLAK' ? 'red--text' :
                          detail.ajuan_status === 'DIKOREKSI' ? 'purple--text' :
                          detail.ajuan_status === 'DISETUJUI' ? 'green--text' :
                          detail.ajuan_status === 'DIHAPUS' ? 'red--text' : ''">
                    {{ detail.ajuan_status }}
                  </span>
                </div>

                <ul
                  class="dashed-list">
                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Badan Usaha.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_nama_badan_usaha }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Nomor NIB.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_nib_no }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Alamat NIB.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_nib_alamat }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Luas.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_luas }} M<sup>2</sup>
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Kegiatan Usaha.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_kbli_judul }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Uraian Kegiatan Usaha.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_kbli_uraian }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Lokasi Usaha.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_lokasi_usaha }}
                    </div>
                  </li>

                  <li
                    class="mb-4">
                    <div
                      class="font-weight-bold mb-1">
                      Alamat.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_dukuh }}, RT{{ detail.ajuan_rt }} / RW{{ detail.ajuan_rw }}, Des. {{ detail.ajuan_kelurahan_name }}, Kec. {{ detail.ajuan_kecamatan_name }}
                    </div>
                  </li>

                  <li>
                    <div
                      class="font-weight-bold mb-1">
                      Keterangan.
                    </div>

                    <div
                      class="line-text-second">
                      {{ detail.ajuan_keterangan === '' ? '-' : detail.ajuan_keterangan }}
                    </div>
                  </li>
                </ul>

                <div
                  class="text-right black--text mt-8">
                  Diajukan Tanggal <strong>{{ detail.ajuan_create_datetime | date }}</strong> Pukul <strong>{{ detail.ajuan_create_datetime | time }}</strong>
                </div>
              </div>

              <div v-else>
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="nama_badan_usaha" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Badan Usaha
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 60%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        placeholder="Contoh. Ternak Ikan"
                        
                        v-model="form.nama_badan_usaha"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nib_no" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nomor NIB
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 40%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        placeholder="Contoh. 0206220065189"
                        
                        v-model="form.nib_no"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nib_alamat" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Alamat NIB
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 70%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        placeholder="Contoh. Jl. Pemuda No. 27"
                        
                        v-model="form.nib_alamat"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kbli" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kegiatan Usaha
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        v-on:keyup.enter="save()"
                        style="max-width: 60%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        placeholder="Contoh. Peternakan Ikan"

                        :search-input.sync="autocomplete.kbli"
                        :loading="process.kbli"

                        clearable
                        @click:clear="form.kbli = ''"
                        
                        :items="config.kbli"
                        item-value="kbli_kbli"
                        item-text="kbli_judul"
                        item-color="#2391d0"

                        v-model="form.kbli"
                        :error-messages="errors"
                        required>
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="lokasi_usaha" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Lokasi Usaha
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 70%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        placeholder="Contoh. Kradenan, Trucuk"
                        
                        v-model="form.lokasi_usaha"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kecamatan_code" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div 
                        class="body-1 mb-2">
                        <span 
                          class="red--text">
                          *
                        </span>

                        <span 
                          style="opacity: 60%;">
                          Kecamatan
                        </span>
                      </div>

                      <v-autocomplete
                        v-on:keyup.enter="save()"
                        style="max-width: 50%;"
                        filled
                        rounded
                        dense 
                        color="#2391d0"

                        placeholder="Contoh. Trucuk"

                        :search-input.sync="autocomplete.subdistrict"
                        :loading="process.subdistrict"
                        
                        :items="config.subdistrict"
                        item-value="kecamatan_code"
                        item-text="kecamatan_name"
                        item-color="#2391d0"

                        v-model="form.kecamatan_code"
                        :error-messages="errors"
                        required>
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kelurahan_code" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div 
                        class="body-1 mb-2">
                        <span 
                          class="red--text">
                          *
                        </span>

                        <span 
                          style="opacity: 60%;">
                          Kelurahan
                        </span>
                      </div>

                      <v-autocomplete
                        v-on:keyup.enter="save()"
                        style="max-width: 50%;"
                        filled
                        rounded
                        dense 
                        color="#2391d0"

                        placeholder="Contoh. Kradenan"

                        :search-input.sync="autocomplete.village"
                        :loading="process.village"
                        
                        :items="config.village"
                        item-value="desa_code"
                        item-text="desa_name"
                        item-color="#2391d0"

                        v-model="form.kelurahan_code"
                        :error-messages="errors"
                        required>
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="dukuh" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Dukuh
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 70%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        placeholder="Contoh. Jl. Pemuda No. 01"
                        
                        v-model="form.dukuh"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0">
                        <ValidationProvider 
                          name="rt" 
                          rules="required" 
                          v-slot="{ errors }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RT
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save()"
                            filled
                            rounded
                            dense
                            single-line
                            color="#2391d0"

                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                            placeholder="Contoh. 22"
                            
                            v-model="form.rt"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        class="py-0">
                        <ValidationProvider 
                          name="rw" 
                          rules="required" 
                          v-slot="{ errors }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RW
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save()"
                            filled
                            rounded
                            dense
                            single-line
                            color="#2391d0"

                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                            placeholder="Contoh. 10"
                            
                            v-model="form.rw"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <ValidationProvider 
                      name="luas" 
                      rules="required" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Luas
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"
                        style="max-width: 30%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"

                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        placeholder="Contoh. 200"
                        
                        v-model="form.luas"
                        :error-messages="errors"
                        required>
                        <template v-slot:append>
                          <div
                            class="black--text my-1"
                            style="opacity: 40%;">
                            M<sup>2</sup>
                          </div>
                        </template>
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        Keterangan
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save"
                        style="max-width: 70%;"
                        filled
                        rounded
                        dense
                        single-line
                        color="#2391d0"
                        rows="5"

                        placeholder="Contoh. Tulis keterangan disini . . ."

                        v-model="form.keterangan">
                      </v-textarea>
                    </div>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form: false
        },
        autocomplete: {
          kbli: '',
          subdistrict: '',
          village: ''
        },
        config: {
          kbli: [],
          subdistrict: [],
          village: []
        },
        form: {
          id: '',
          nama_badan_usaha: '',
          nib_no: '',
          nib_alamat: '',
          kbli: '',
          lokasi_usaha: '',
          luas: '',
          keterangan: '',
          lampiran: [],
          koordinat: [],
          extra: {},

          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: ''
        },
        process: {
          load: false,
          form: false,
          kbli: false,
          subdistrict: false,
          village: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      'autocomplete.kbli': function() {
        this.static().kbli()
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      static () {
        let t = this
        return {
          async kbli () {
            t.process.kbli = true

            let params = {
              search: t.autocomplete.kbli
            }

            await t.$axios.$get(`${process.env.PREFIX_PROXY}/master/kbli`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.kbli = false

                t.config.kbli = response.data
              }
            })
          },
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          id: this.detail.id,
          nama_badan_usaha: this.detail.ajuan_nama_badan_usaha,
          nib_no: this.detail.ajuan_nib_no,
          nib_alamat: this.detail.ajuan_nib_alamat,
          kbli: this.detail.ajuan_kbli,
          lokasi_usaha: this.detail.ajuan_lokasi_usaha,
          luas: this.detail.ajuan_luas,
          keterangan: this.detail.ajuan_keterangan,
          lampiran: this.detail.ajuan_lampiran,
          koordinat: this.detail.ajuan_koordinat,
          extra: this.detail.ajuan_extra,

          kecamatan_code: this.detail.ajuan_kecamatan_code,
          kecamatan_name: this.detail.ajuan_kecamatan_name,
          kelurahan_code: this.detail.ajuan_kelurahan_code,
          kelurahan_name: this.detail.ajuan_kelurahan_name,
          dukuh: this.detail.ajuan_dukuh,
          rt: this.detail.ajuan_rt,
          rw: this.detail.ajuan_rw
        }

        this.process.form = false

        this.message.error = ''

        this.$emit('goToTop', false)
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/update`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.sheet.form = false

              this.reset()

              this.$emit('load', false)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style scoped>
  ul.dashed-list {
    list-style: none outside none;
  }

  ul.dashed-list li:before {
    content: "\2014";
    float: left;
    margin: 0 0 0 -24px;
    padding: 0;
  }

  ul.dashed-list li {
    list-style-type: none;
  }
</style>