<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="white"
            height="64"
            style="border-radius: 0px;">
          </v-card>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')"
            style="-moz-transform: scale(-1, -1);
                  -o-transform: scale(-1, -1);
                  -webkit-transform: scale(-1, -1);
                  transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-img
            contain
            width="100%"
            height="200"
            class="ma-auto"
            alt="vuetify"
            :src="require('@/assets/image/example/wireless.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          
          <v-container
            class="pt-0 pb-16 mb-16">
            <v-row 
              justify="center">
              <v-col 
                cols="12"
                md="10"
                class="pt-0 pb-16">
                <v-card
                  flat
                  color="white"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 50px;">
                  <v-row
                    align="center">
                    <v-col 
                      cols="7"
                      class="py-0 pr-0">
                      <v-img
                        width="100%"
                        height="68vh"
                        class="ma-auto"
                        gradient="to bottom, rgba(0, 62, 105, .1), rgba(0, 62, 105, .5)"
                        :src="require('@/assets/background/bg_banner_2.jpeg')"
                        style="border-radius: 50px 0px 0px 50px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <!-- <v-card
                        flat
                        class="d-flex align-center justify-center pa-16 pr-0"
                        min-height="73vh"
                        color="#cacaca"
                        style="border-radius: 50px 0px 0px 50px;">
                        <v-card-text
                          class="pr-0">
                          <v-img
                            contain
                            width="100%"
                            height="30vh"
                            class="ma-auto"
                            :src="require('@/assets/background/bg_banner_2.jpeg')"
                            style="border-radius: 50px 0px 0px 50px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card-text>
                      </v-card> -->
                    </v-col>

                    <v-col 
                      cols="5"
                      class="pr-6">
                      <v-card-text
                        class="mb-4">
                        <v-list 
                          dense 
                          color="transparent" 
                          class="py-0">
                          <v-list-item 
                            class="px-0">
                            <v-list-item-avatar 
                              tile
                              size="80" 
                              class="mt-0">
                              <v-img 
                                alt="kominfo" 
                                :src="require(`@/assets/image/time/${isDay}.png`)"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="white">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div 
                                class="text-h5 text-capitalize font-weight-bold black--text one-line mb-0">
                                Hai . . .
                              </div>
                              
                              <div 
                                class="body-1 font-weight-light black--text" 
                                style="opacity: 60%;">
                                Selamat {{ isDay }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-text>
                        <div class="title font-weight-bold">
                          Reset Password
                        </div>

                        <div class="body-1 font-weight-light"
                          style="opacity: 60%;">
                         SITR Online Klaten
                        </div>
                      </v-card-text>
                      
                      <v-card-text>
                        <ValidationObserver 
                          ref="form">
                          <v-form>
                            <ValidationProvider 
                              name="new_password"
                              vid="confirm" 
                              rules="required|min:5|max:20" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Masukkan Password
                                </span>
                              </div>
                              
                              <v-text-field
                                v-on:keyup.enter="save"
                                filled
                                rounded
                                dense 
                                color="#2391d0"

                                placeholder="Contoh. ******"

                                :type="show.new_password ? 'text' : 'password'"
                                :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show.new_password = !show.new_password"
                                :counter="20"

                                v-model="form.new_password"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>

                            <ValidationProvider 
                              name="new_password_verify" 
                              rules="required|min:5|max:20|confirmed:confirm" 
                              v-slot="{ errors }">
                              <div 
                                class="body-1 mb-2">
                                <span 
                                  class="red--text">
                                  *
                                </span>

                                <span 
                                  style="opacity: 60%;">
                                  Masukkan Verifikasi Password
                                </span>
                              </div>
                              
                              <v-text-field
                                v-on:keyup.enter="save"
                                filled
                                rounded
                                dense 
                                color="#2391d0"

                                placeholder="Contoh. ******"

                                :type="show.new_password_verify ? 'text' : 'password'"
                                :append-icon="show.new_password_verify ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show.new_password_verify = !show.new_password_verify"
                                :counter="20"

                                v-model="form.new_password_verify"
                                :error-messages="errors"
                                required
                                class="mb-5">
                              </v-text-field>
                            </ValidationProvider>

                            <div v-if="message.error.length > 0"
                              class="body-3 red--text mb-4">
                              <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                              {{ message.error }}
                            </div>

                            <v-btn
                              :loading="process.form"
                              :disabled="process.form"
                              v-on:keyup.enter="save"
                              @click="save"
                              rounded
                              large
                              block
                              color="#2391d0"
                              elevation="0"
                              class="body-1 font-weight-bold white--text text-capitalize">
                              Reset Password
                            </v-btn>

                            <div class="d-flex align-center ma-6 mx-12">
                              <v-divider/>

                              <span class="body-1 font-weight-bold mx-3">
                                Atau
                              </span>

                              <v-divider/>
                            </div>

                            <v-btn
                              to="/auth/login"
                              :disabled="process.form"
                              outlined
                              rounded
                              large
                              block
                              color="#2391d0"
                              elevation="0"
                              class="body-1 font-weight-bold text-capitalize color-default">
                              Masuk Sekarang
                            </v-btn>
                          </v-form>
                        </ValidationObserver>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-img
            width="100%"
            height="auto"
            :src="require('@/assets/background/header_section_1.png')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: {
          new_password: false,
          new_password_verify: false
        },
        form: {
          token: '',
          new_password: '',
          new_password_verify: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/reset/:slug',
          title: 'Reset Password',
          description: 'Reset Password SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      isDay() {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'

        return day
      }
    },
    mounted () {
      this.form.token = this.$route.params.slug
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
           await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/reset-password`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.$router.push({ path: '/auth/successforgot' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>