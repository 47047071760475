<template>
  <div class="fill-height pt-16 mt-8 mb-10"
    id="list">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="min-height: 52vh;">
            <v-row v-if="process.load && Object.keys(detail).length < 1">
              <v-col 
                cols="12" 
                md="3">
                <v-skeleton-loader
                  :loading="process.load"
                  type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col 
                cols="12" 
                md="9">
                <v-skeleton-loader
                  :loading="process.load"
                  type="article, list-item-two-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, list-item-two-line, list-item-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row v-if="!process.load && Object.keys(detail).length > 0"
              justify="center">
              <v-col 
                cols="12" 
                md="3">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3" 
                  style="position: sticky; top: 120px;">
                  <nuxt-link
                    to="/dashboard?menu=submission">
                    <div
                      class="d-flex align-center my-2">
                      <v-icon color="grey">mdi-arrow-left-thick</v-icon>

                      <span
                        class="black--text ml-1"
                        style="opacity: 60%;">
                        Kembali
                      </span>
                    </div>
                  </nuxt-link>

                  <v-list 
                    flat 
                    two-line 
                    class="py-0">
                    <v-list-item 
                      class="px-0">
                      <v-list-item-avatar 
                        tile 
                        size="50" 
                        class="mr-3">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="user.image === '' ?  require('@/assets/image/member_default.jpg') : user.image">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title 
                          class="body-1 text-capitalize font-weight-bold mb-2">
                           {{ user.fullname }}
                        </v-list-item-title>

                        <v-list-item-subtitle 
                          class="body-2 text-capitalize font-weight-light">
                          <span 
                            class="color-first d-flex align-center">
                            <v-icon size="18" color="#2391d0">mdi-seal-variant</v-icon>

                            {{ user.nik }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider 
                    class="mb-3"
                    style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />

                  <div
                    v-for="(item, index) in menu"
                    :key="index">
                    <div 
                      class="px-2">
                      <div 
                        class="body-1 font-weight-bold mb-4">
                        {{ item.title }}
                      </div>

                      <div 
                        v-for="(item_sub, index_sub) in item.sub_menu"
                        :key="index_sub"
                        @click="selected.menu = index; selected.sub_menu = index_sub; changeQuery(item_sub.link)"
                        class="body-2 d-flex align-center cursor-pointer mb-4">
                        <v-icon 
                          size="20"
                          class="mr-2"
                          :color="selected.menu === index && selected.sub_menu === index_sub ? '#2391d0' : '#7B7B7B'">
                          {{ item_sub.icon }}
                        </v-icon>

                        <span 
                          :class="selected.menu === index && selected.sub_menu === index_sub ? 'color-default font-weight-bold' : 'text-second'">
                          {{ item_sub.text }}
                        </span>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col 
                cols="12" 
                md="9">
                <div>
                  <Detail v-if="selected.menu === 0 && selected.sub_menu === 0"
                    :detail="detail"
                    @load="fetch()" />

                  <Maping v-if="selected.menu === 0 && selected.sub_menu === 1"
                    :detail="detail"
                    @load="fetch()"  />

                  <Attachment v-if="selected.menu === 0 && selected.sub_menu === 2"
                    :detail="detail"
                    @load="fetch()" />

                  <Log v-if="selected.menu === 0 && selected.sub_menu === 3"
                    :detail="detail"
                    @load="fetch()" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        selected: {
          menu: 0,
          sub_menu: 0
        },
        menu: [
          {
            title: 'Ajuan Saya',
            sub_menu: []
          }
        ],
        detail: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/submission/:id',
          title: 'Detail Ajuan',
          description: 'Detail Ajuan SITR Online Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Detail: () => import('@/components/submission/detail'),
      Maping: () => import('@/components/submission/maping'),
      Attachment: () => import('@/components/submission/attachment'),
      Log: () => import('@/components/submission/log')
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    beforeMount () {
      this.changeLink(this.$route.query.menu)
    },
    mounted () {
      this.fetch()
    },
    methods: {
      changeLink(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        if (query !== undefined) {
          if (query === 'detail') {
            this.selected = {
              menu: 0,
              sub_menu: 0
            }
          } else if (query === 'maping') {
            this.selected = {
              menu: 0,
              sub_menu: 1
            }
          } else if (query === 'attachment') {
            this.selected = {
              menu: 0,
              sub_menu: 2
            }
          } else if (query === 'log') {
            this.selected = {
              menu: 0,
              sub_menu: 3
            }
          }
        }
      },
      changeQuery(link) {
        this.$router.push(link)
      },
      async fetch () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.detail = response.data

            this.menu[0].sub_menu = [
              {
                text: 'Detail Ajuan',
                icon: 'mdi-file-document-multiple-outline',
                link: `/submission/${this.$route.params.id}?menu=detail`
              },
              {
                text: 'Maping',
                icon: 'mdi-map-marker-path',
                link: `/submission/${this.$route.params.id}?menu=maping`
              },
              {
                text: 'Lampiran',
                icon: 'mdi-paperclip',
                link: `/submission/${this.$route.params.id}?menu=attachment`
              },
              {
                text: 'Log Status',
                icon: 'mdi-list-status',
                link: `/submission/${this.$route.params.id}?menu=log`
              }
            ]
          } 
        })
      }
    }
  }
</script>