<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog 
          v-model="dialog"
          @keydown.esc="dialog = false"
          width="400"
          persistent
          scrollable>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              Ubah Password

              <v-spacer/>

              <v-icon 
                @click="dialog = false; reset();"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>

            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="pt-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="old_password" 
                    rules="required|min:5|max:20" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Password Lama
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 100%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"
                      
                      placeholder="Contoh. ******"
                      :type="show.old_password ? 'text' : 'password'"
                      :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show.old_password = !show.old_password"
                      :counter="20"
                      
                      v-model="form.old_password"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="new_password" 
                    rules="required|min:5|max:20" 
                    v-slot="{ errors }">
                    <div 
                      class="body-1 mb-2">
                      <span 
                        class="red--text">
                        *
                      </span>

                      <span 
                        style="opacity: 60%;">
                        Password Baru
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save()"
                      style="max-width: 100%;"
                      filled
                      rounded
                      dense 
                      color="#2391d0"
                      
                      placeholder="Contoh. ******"
                      :type="show.new_password ? 'text' : 'password'"
                      :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show.new_password = !show.new_password"
                      :counter="20"
                      
                      v-model="form.new_password"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="save()"
                @click="save()"
                color="#6dbc45"
                elevation="0"
                rounded
                class="body-2 font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Simpan
              </v-btn>

              <v-btn
                :disabled="process.form"
                @click="dialog = false; reset();"
                outlined
                rounded
                class="body-2 font-weight-bold text-capitalize red--text">
                <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            color="white"
            class="box-shadow border-radius pa-3">
            <v-card-text>
              <v-chip
                class="font-weight-bold white--text border-radius px-4"
                color="#848484">
                NIK - {{ detail.nik }}
              </v-chip>

              <div
                class="text-h5 font-weight-bold black--text line-text-second my-2">
                {{ detail.fullname }}
              </div>

              <div
                class="mb-6">
                Password. ********
              </div>

              <div
                class="mb-2">
                Anda dapat merubah password dengan menekan tombol dibawah ini:
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="dialog = true; reset();"
                elevation="0"
                large
                rounded
                style="background: #ff980033;"
                class="body-1 text-capitalize font-weight-bold d-flex align-center orange--text px-4 mb-2">
                <v-icon size="20">mdi-lock-reset</v-icon>

                <span class="ml-1">
                  Ubah Password
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: false,
        show: {
          old_password: false,
          new_password: false
        },
        form: {
          old_password: '',
          new_password: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {

    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          old_password: '',
          new_password: ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
           await this.$axios.$post(`${process.env.PREFIX_PROXY}/profile/update_password`, this.form)
          .then((response) => {
            this.process.form = false

            if (response.code === 200) {
              this.dialog = false

              this.reset()

              this.$emit('load', false)
            } else {
              this.message.error = response.message
            }

          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>