<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.attachment"
          @keydown.esc="dialog.attachment = false"
          :fullscreen="dialog.fullscreen"
          width="1000"
          persistent
          scrollable>
          <v-card v-if="Object.keys(selected.attachment).length > 0"
            :class="dialog.fullscreen ? 'non-border-radius' : 'border-radius'"
            class="pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              Lampiran {{ selected.attachment.type === 'image' ? 'Gambar' : 'PDF' }}

              <v-spacer/>

              {{dialog.attachment}}

              <v-icon 
                @click="dialog.fullscreen = !dialog.fullscreen ? true : false"
                class="cursor-pointer mr-1"
                size="24"
                color="#2391d0">
                {{ !dialog.fullscreen ? 'mdi-fullscreen' : 'mdi-fullscreen-exit' }}
              </v-icon>

              <v-icon 
                @click="dialog.attachment = false; dialog.fullscreen = false;"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="mt-4">
              <v-img v-if="selected.attachment.type === 'image'"
                width="100%"
                height="auto"
                alt="locafe" 
                :src="selected.attachment.url"
                class="ma-auto border-radius">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#2391d0">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div v-if="selected.attachment.type === 'document'">
                <iframe 
                  :src="`${selected.attachment.url}#toolbar=0`" 
                  allowfullscreen
                  frameborder="0" 
                  width="100%"
                  height="1000"
                  style="border-radius: 15px; border: 2px solid #323639;">
                </iframe>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.upload"
          @keydown.esc="dialog.upload = false"
          width="500"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              Upload Lampiran

              <v-spacer/>

              <v-icon 
                @click="dialog.upload = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="d-flex align-center mt-4">
              <div
                class="text-center">
                <upload-folder v-model="upload.folder">
                  <div 
                    slot="activator" 
                    class="cursor-pointer">
                    <v-btn
                      fab
                      color="#f0f0f0"
                      width="100"
                      height="100"
                      elevation="0">
                      <v-img
                        width="70"
                        height="70"
                        contain
                        alt="kominfo"
                        :src="require('@/assets/icon/upload_image.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-btn>

                    <div
                      class="text-center font-weight-bold black--text mt-2">
                      Gambar
                    </div>
                  </div>
                </upload-folder>
              </div>

              <v-spacer />

              <div 
                class="d-flex align-center"
                style="width: 150px;">
                <v-divider 
                  style="border: 2px solid #eaeaea; border-radius: 50px;" />

                <div 
                  class="mx-2 font-weight-bold"
                  style="opacity: 80%;">
                  Atau
                </div>

                <v-divider 
                  style="border: 2px solid #eaeaea; border-radius: 50px;" />
              </div>

              <v-spacer />

              <div
                class="text-center">
                <upload-document v-model="upload.document">
                  <div 
                    slot="activator" 
                    class="cursor-pointer">
                    <v-btn
                      fab
                      color="#f0f0f0"
                      width="100"
                      height="100"
                      elevation="0">
                      <v-img
                        width="60"
                        height="60"
                        contain
                        alt="kominfo"
                        :src="require('@/assets/icon/upload_folder.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-btn>

                    <div
                      class="text-center font-weight-bold black--text mt-2">
                      PDF
                    </div>
                  </div>
                </upload-document>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        
        <section>
          <v-card 
            color="white"
            class="box-shadow border-radius">
            <v-card-text>
              <div
                class="title font-weight-bold black--text mb-1">
                Lampiran
              </div>

              <div
                class="d-flex align-center"
                :class="message.error.length > 0 ? 'mb-2' : 'mb-4'">
                <div>
                  Ubah dokumen ajuan Anda, dengan klik tombol <strong>Ubah Lampiran</strong>.
                </div>

                <v-spacer />

                <div v-if="!sheet.form">
                  <v-btn
                    @click="sheet.form = true; reset()"
                    small
                    rounded
                    elevation="0"
                    color="orange"
                    class="text-capitalize white--text">
                    Ubah Lampiran
                  </v-btn>
                </div>

                <div v-else>
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save()"
                    @click="save()"
                    small
                    rounded
                    elevation="0"
                    color="#6dbc45"
                    class="body-2 font-weight-bold text-capitalize white--text">
                    <v-icon small class="mr-1">mdi-check-bold</v-icon>
                    Simpan
                  </v-btn>

                  <v-btn
                    :disabled="process.form"
                    @click="sheet.form = false; reset();"
                    small
                    rounded
                    outlined
                    class="body-2 font-weight-bold text-capitalize red--text">
                    <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                    Batal
                  </v-btn>
                </div>
              </div>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>

              <div v-if="!sheet.form">
                <v-card 
                  v-for="(item, index) in detail.ajuan_lampiran"
                  :key="index"
                  flat
                  color="transparent"
                  class="mb-6">
                  <div
                    class="pb-2">
                    <span
                      class="line-text-second"
                      style="opacity: 70%;"
                      v-html="item.name">
                    </span>
                  </div>

                  <v-divider 
                    style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />
                  
                  <div v-if="item.file.length > 0"
                    class="mt-4">
                    <v-row>
                      <v-col 
                        v-for="(item_img, index_img) in item.file"
                        :key="index_img"
                        cols="6" 
                        md="4">
                        <v-card 
                          @click="selected.attachment = item_img; dialog.attachment = true;"
                          flat
                          width="100%"
                          height="175"
                          class="d-flex align-center justify-center"
                          style="border-radius: 15px;
                                border: 2px solid #eaeaea; 
                                border-style: dashed;
                                box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                          <v-img v-if="item_img.type === 'image'"
                            alt="kominfo" 
                            width="100%"
                            height="175"
                            :src="item_img.url"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <div 
                              class="font-weight-bold color-default"
                              style="position: absolute; top: 5px; left: 5px;">
                              Gambar
                            </div>
                          </v-img>

                          <div v-if="item_img.type === 'document'"
                            class="d-flex align-center justify-center">
                            <iframe 
                              :src="`${item_img.url}#toolbar=0`" 
                              allowfullscreen
                              frameborder="0" 
                              width="100%"
                              height="175"
                              style="border-radius: 15px; border: 2px solid #323639;">
                            </iframe>

                            <div
                              style="width: 100%; height: 175px; position: absolute; background: transparent;">
                            </div>

                            <div 
                              class="font-weight-bold color-default"
                              style="position: absolute; top: 5px; left: 5px;">
                              PDF
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-else
                    class="mt-4 orange--text font-italic">
                    Lampiran belum tersedia
                  </div>
                </v-card>
              </div>

              <div v-else>
                <v-card 
                  flat
                  color="transparent"
                  class="mb-6">
                  <ValidationObserver 
                    ref="form">
                    <v-form>
                      <v-card
                        v-for="(item, index) in form.lampiran"
                        :key="index"
                        flat
                        color="transparent"
                        class="mb-6">
                        <div
                          class="body-2 d-flex align-start pb-2">
                          <span v-if="item.require" class="red--text ml-0 ma-1" >*</span>

                          <span
                            class="line-text-second"
                            style="opacity: 60%;"
                            v-html="item.name">
                          </span>

                          <span v-if="item.require"
                            class="error--text ml-1"
                            style="width: 175px; margin: 2px;">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-divider 
                          style="border-top: 5px solid #f3f3f3; border-radius: 50px;" />

                        <div
                          class="mt-4">
                          <v-row>
                            <v-col 
                              v-for="(item_img, index_img) in item.file"
                              :key="index_img"
                              cols="6" 
                              md="4">
                              <v-card 
                                flat
                                width="100%"
                                height="175"
                                class="d-flex align-center justify-center"
                                style="border-radius: 15px;
                                      border: 2px solid #eaeaea; 
                                      border-style: dashed;
                                      box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                                <!-- <v-img 
                                  alt="kominfo" 
                                  width="100%"
                                  height="175"
                                  :src="item_img.url"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#2391d0">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>

                                  <div @click="item.file.splice(index_img, 1)"
                                    class="cursor-pointer"
                                    style="position: absolute; top: 5px; right: 5px;">
                                    <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                  </div>
                                </v-img> -->

                                <v-img v-if="item_img.type === 'image'"
                                  alt="kominfo" 
                                  width="100%"
                                  height="175"
                                  :src="item_img.url"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#2391d0">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>

                                  <div @click="item.file.splice(index_img, 1)"
                                    class="cursor-pointer"
                                    style="position: absolute; top: 5px; right: 5px;">
                                    <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                  </div>

                                  <div 
                                    class="font-weight-bold color-default"
                                    style="position: absolute; top: 5px; left: 5px;">
                                    Gambar
                                  </div>
                                </v-img>
                                
                                <div v-if="item_img.type === 'document'"
                                  class="d-flex align-center justify-center">
                                  <iframe 
                                    :src="`${item_img.url}#toolbar=0`" 
                                    allowfullscreen
                                    frameborder="0" 
                                    width="100%"
                                    height="175"
                                    style="border-radius: 15px; border: 2px solid #323639;">
                                  </iframe>

                                  <div @click="item.file.splice(index_img, 1)"
                                    class="cursor-pointer"
                                    style="position: absolute; top: 5px; right: 5px;">
                                    <v-icon size="20" color="red">mdi-close-circle</v-icon>
                                  </div>

                                  <div 
                                    class="font-weight-bold color-default"
                                    style="position: absolute; top: 5px; left: 5px;">
                                    PDF
                                  </div>
                                </div>
                              </v-card>
                            </v-col>

                            <v-col 
                              cols="6"
                              md="4">
                              <!-- <upload-folder v-model="upload.folder">
                                <div 
                                  slot="activator" 
                                  class="cursor-pointer">
                                  <v-card @click="index_file = index"
                                    flat
                                    width="100%"
                                    height="175"
                                    class="d-flex align-center justify-center"
                                    style="border-radius: 30px;
                                          border: 2px solid #eaeaea; 
                                          border-style: dashed;">
                                    <v-icon v-if="!process.file || index_file !== index"
                                      size="50" 
                                      color="grey">
                                      mdi-cloud-upload-outline
                                    </v-icon>

                                    <v-progress-circular v-if="process.file && index_file === index"
                                      indeterminate
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-card>
                                </div>
                              </upload-folder> -->

                              <v-card @click="dialog.upload = true; index_file = index;"
                                flat
                                width="100%"
                                height="175"
                                class="d-flex align-center justify-center"
                                style="border-radius: 30px;
                                      border: 2px solid #eaeaea; 
                                      border-style: dashed;">
                                <v-icon v-if="(!process.file && !process.doc) || index_file !== index"
                                  size="50" 
                                  color="grey">
                                  mdi-cloud-upload-outline
                                </v-icon>

                                <v-progress-circular v-if="(process.file || process.doc) && index_file === index"
                                  indeterminate
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-form>
                  </ValidationObserver>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          fullscreen: false,
          attachment: false,
          upload: false
        },
        sheet: {
          form: false
        },
        selected: {
          attachment: {}
        },
        upload: {
          folder: null,
          document: null
        },
        index_file: '',
        form: {
          id: '',
          nama_badan_usaha: '',
          nib_no: '',
          nib_alamat: '',
          kbli: '',
          lokasi_usaha: '',
          luas: '',
          keterangan: '',
          lampiran: [],
          koordinat: [],
          extra: {}
        },
        process: {
          load: false,
          form: false,
          file: false,
          doc: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      UploadDocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.dialog.upload = false

            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.form.lampiran[this.index_file].file.push({
                url: this.upload.folder.data.full.file_url,
                type: this.upload.folder.type,
                process: this.upload.folder.process,
                user_id: this.user.id,
                file_id: this.upload.folder.id === undefined ? 0 : this.upload.folder.id
              })
            }
          }
        },
        deep: true
      },
      'upload.document': {
        handler: function () {
          if (this.upload.document) {
            this.dialog.upload = false

            this.process.doc = this.upload.document.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD PDF
              this.form.lampiran[this.index_file].file.push({
                url: this.upload.document.data.file_url,
                type: this.upload.document.type,
                process: this.upload.document.process,
                user_id: this.user.id,
                file_id: this.upload.document.id === undefined ? 0 : this.upload.document.id
              })
            }
          }
        },
        deep: true
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          id: this.detail.id,
          nama_badan_usaha: this.detail.ajuan_nama_badan_usaha,
          nib_no: this.detail.ajuan_nib_no,
          nib_alamat: this.detail.ajuan_nib_alamat,
          kbli: this.detail.ajuan_kbli,
          lokasi_usaha: this.detail.ajuan_lokasi_usaha,
          luas: this.detail.ajuan_luas,
          keterangan: this.detail.ajuan_keterangan,
          lampiran: this.detail.ajuan_lampiran,
          koordinat: this.detail.ajuan_koordinat,
          extra: this.detail.ajuan_extra
        }

        this.process.form = false

        this.message.error = ''

        this.$emit('goToTop', false)
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/update`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.sheet.form = false

              this.reset()

              this.$emit('load', false)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>