<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <div
              class="d-flex align-center mb-4">
              <v-spacer />

              <v-text-field
                style="max-width: 50%;"
                filled
                rounded
                dense
                single-line
                color="#2391d0"

                hide-details

                placeholder="Cari data yang Anda butuhkan . . ."

                clearable
                
                v-model="search"
                v-on:keyup.enter="fetch()"
                @click:clear="search = ''; fetch();">
              </v-text-field>
            </div>

            <v-row>
              <v-col
                v-for="(item, index) in process.load ? 5 : list"
                :key="index"
                cols="12"
                class="py-0 pb-4">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <div>
                      <v-list 
                        flat 
                        three-line
                        class="py-0">
                        <v-list-item
                          :to="`/submission/${item.id}`">
                          <v-list-item-avatar 
                            tile 
                            size="75" 
                            class="mr-4">
                            <v-img 
                              style="border-radius: 10px;"
                              src="https://margondes.com/wp-content/uploads/2014/12/DesaJurangjero.png">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title 
                              class="body-1 text-capitalize font-weight-bold mb-1">
                              {{ item.ajuan_nama_badan_usaha }}
                            </v-list-item-title>

                            <div
                              class="black--text text-capitalize">
                              <div
                                class="caption mb-2">
                                NIB - {{ item.ajuan_nib_no }} | ALamat. {{ item.ajuan_nib_alamat }}
                              </div>

                              <v-divider/>

                              <div
                                style="opacity: 60%;">
                                <div
                                  class="body-2 line-text-first my-2">
                                  - Nomor Registrasi. {{ item.ajuan_no_reg }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Luas. {{ item.ajuan_luas }} M<sup>2</sup>
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Kegiatan Usaha. {{ item.ajuan_kbli_judul }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Lokasi Usaha. {{ item.ajuan_lokasi_usaha }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Alamat. {{ item.ajuan_dukuh }}, RT{{ item.ajuan_rt }} / RW{{ item.ajuan_rw }}, Des. {{ item.ajuan_kelurahan_name }}, Kec. {{ item.ajuan_kecamatan_name }}
                                </div>

                                <div
                                  class="body-2 line-text-first my-2">
                                  - Keterangan. {{ item.ajuan_keterangan === '' ? '-' : item.ajuan_keterangan }}
                                </div>
                              </div>

                              <div>
                                <div
                                  class="body-2 line-text-first my-2">
                                  <span
                                    style="opacity: 60%;">
                                    - Status.
                                  </span>

                                  <span
                                    :class="item.ajuan_status === 'MENUNGGU VERIFIKASI' ? 'orange--text' :
                                          item.ajuan_status === 'DIPROSES' ? 'blue--text' :
                                          item.ajuan_status === 'DITOLAK' ? 'red--text' :
                                          item.ajuan_status === 'DIKOREKSI' ? 'purple--text' :
                                          item.ajuan_status === 'DISETUJUI' ? 'green--text' :
                                          item.ajuan_status === 'DIHAPUS' ? 'red--text' : ''">
                                    {{ item.ajuan_status }}
                                  </span>
                                </div>
                              </div>

                              <v-divider/>

                              <div
                                class="caption mt-2">
                                Tanggal Pengajuan {{ item.ajuan_create_datetime | date }} Pukul {{ item.ajuan_create_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>

              <v-col v-if="!process.load && pagination.total_page < 1">
                <v-card 
                  color="white"
                  class="box-shadow border-radius pa-3">
                  <Empty
                    size="150"
                    message="Riwayat Ajuan Disetujui" />
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        search: '',
        list: [],
        pagination: {},
        process: {
          load: false
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          search: this.search,
          ajuan_status: 'DISETUJUI'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/history`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>