<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            color="white"
            class="box-shadow border-radius">
            <v-card-text>
              <div
                class="title font-weight-bold black--text mb-1">
                Map Lokasi
              </div>

              <div
                class="d-flex align-center"
                :class="message.error.length > 0 ? 'mb-2' : 'mb-4'">
                <div>
                  Ubah titik ajuan Anda, dengan klik tombol <strong>Ubah Koordinat</strong>.
                </div>

                <v-spacer />

                <div v-if="!sheet.form">
                  <v-btn
                    @click="sheet.form = true; reset();"
                    small
                    rounded
                    elevation="0"
                    color="orange"
                    class="text-capitalize white--text">
                    Ubah Koordinat
                  </v-btn>
                </div>

                <div v-else>
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save()"
                    @click="save()"
                    small
                    rounded
                    elevation="0"
                    color="#6dbc45"
                    class="body-2 font-weight-bold text-capitalize white--text">
                    <v-icon small class="mr-1">mdi-check-bold</v-icon>
                    Simpan
                  </v-btn>

                  <v-btn
                    :disabled="process.form"
                    @click="sheet.form = false; reset(); $emit('load', false);"
                    small
                    rounded
                    outlined
                    class="body-2 font-weight-bold text-capitalize red--text">
                    <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
                    Batal
                  </v-btn>
                </div>
              </div>

              <div v-if="message.error.length > 0"
                class="body-3 red--text mb-4">
                <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                {{ message.error }}
              </div>

              <v-card
                flat
                min-height="70vh"
                color="transparent"
                class="mb-6"
                style="border-radius: 15px;">
                <div 
                  id="map" 
                  style="width: 100%; height: 70vh;">
                </div>
              </v-card>

              <div v-if="!sheet.form">  
                <div
                  class="mb-1 font-weight-bold d-flex align-center">
                  <v-icon size="18">mdi-map-marker-path</v-icon>

                  <span
                    class="ml-1">
                    Koordinat Lokasi Usaha
                  </span>
                </div>

                <div
                  class="mb-4">
                  Berikut adalah koordinat lokasi usaha yang diajukan:
                </div>

                <v-card v-if="detail.ajuan_koordinat.length > 0"
                  flat
                  color="#f0f0f033"
                  style="border-radius: 15px; border: 3px solid #f0f0f0;">
                  <v-card-text
                    class="py-1">
                    <v-row
                      v-for="(item, index) in detail.ajuan_koordinat"
                      :key="index"
                      align="center"
                      class="mx-1"
                      :style="index < detail.ajuan_koordinat.length - 1 ? 'border-bottom: 2px dashed #f0f0f0;' : ''">
                      <v-col
                        cols="12"
                        md="6"
                        class="px-0">
                        <div class="body-2 mb-1">
                          Latitude <strong>{{ index + 1 }}</strong>
                        </div>

                        <div>
                          {{ item.lat }}
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        class="px-0">
                        <div class="body-2 mb-1">
                          Longitude <strong>{{ index + 1 }}</strong>
                        </div>

                        <div>
                          {{ item.lng }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>

              <div v-else>
                <div>
                  #Jika ingin menambahkan koordinat secara manual, silahkan klik tombol <strong>Tambah Koordinat</strong>.
                </div>
                
                <v-btn
                  @click="addLatlng()"
                  rounded
                  color="#333333"
                  elevation="0"
                  class="text-capitalize white--text my-4">
                  Tambah Koordinat
                </v-btn>

                <v-card v-if="form.koordinat.length > 0"
                  flat
                  color="#f0f0f033"
                  style="border-radius: 15px; border: 3px solid #f0f0f0;">
                  <v-card-text>
                    <v-row
                      v-for="(item, index) in form.koordinat"
                      :key="index"
                      align="center">
                      <v-col
                        cols="12"
                        md="5"
                        class="py-0">
                        <ValidationProvider 
                          name="lat" 
                          rules="required" 
                          v-slot="{ errors }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Latitude <strong>{{ index + 1 }}</strong>
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save()"
                            type="number"   
                            filled
                            rounded
                            dense
                            single-line
                            color="#2391d0"

                            placeholder="Contoh. -7.7126396311"

                            @blur="initLatlng()"
                            
                            v-model.number="item.lat"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="12"
                        md="5"
                        class="py-0">
                        <ValidationProvider 
                          name="lng" 
                          rules="required" 
                          v-slot="{ errors }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Longitude <strong>{{ index + 1 }}</strong>
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save()"
                            type="number"   
                            filled
                            rounded
                            dense
                            single-line
                            color="#2391d0"

                            placeholder="Contoh. 110.5909243135"

                            @blur="initLatlng()"
                            
                            v-model.number="item.lng"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        class="text-center py-0">
                        <v-btn
                          @click="removeLatlng(index)"
                          rounded
                          color="red"
                          elevation="0"
                          class="body-2 white--text text-capitalize d-flex align-center">
                          <v-icon size="20" color="white">mdi-delete-empty</v-icon>

                          <span
                            class="ml-1">
                            Hapus
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form: false
        },
        options: {
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeColor: '#FF0000',
          strokeWeight: 2,
          strokeOpacity: 1,
        },
        
        // MAPING
        map: null,

        form: {
          id: '',
          nama_badan_usaha: '',
          nib_no: '',
          nib_alamat: '',
          kbli: '',
          lokasi_usaha: '',
          luas: '',
          keterangan: '',
          lampiran: [],
          koordinat: [],
          extra: {}
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.initMap()
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          id: this.detail.id,
          nama_badan_usaha: this.detail.ajuan_nama_badan_usaha,
          nib_no: this.detail.ajuan_nib_no,
          nib_alamat: this.detail.ajuan_nib_alamat,
          kbli: this.detail.ajuan_kbli,
          lokasi_usaha: this.detail.ajuan_lokasi_usaha,
          luas: this.detail.ajuan_luas,
          keterangan: this.detail.ajuan_keterangan,
          lampiran: this.detail.ajuan_lampiran,
          koordinat: this.detail.ajuan_koordinat,
          extra: this.detail.ajuan_extra
        }

        this.process.form = false

        this.message.error = ''

        this.$emit('goToTop', false)

        this.initMap()
      },
      initMap () {
        let self = this

        this.$gmapApiPromiseLazy().then(() => {
          self.map = new google.maps.Map(document.getElementById('map'), {
            center: { 
              lat: this.detail.ajuan_koordinat.length > 0 ? this.detail.ajuan_koordinat[0].lat : -7.71210962,
              lng: this.detail.ajuan_koordinat.length > 0 ? this.detail.ajuan_koordinat[0].lng : 110.59174574
            },
            zoom: 18,
            noClear: true,
            mapTypeId: 'hybrid', //satellite
            navigationControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            tilt: 0
          })

          self.map.data.addListener('addfeature', self.featureAdded)

          const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: this.detail.ajuan_koordinat.length < 1 ? google.maps.drawing.OverlayType.POLYGON : null,
            drawingControl: self.sheet.form,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_LEFT,
              drawingModes: ['polygon']
            },
            polygonOptions: {
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              strokeColor: '#FF0000',
              strokeWeight: 2,
              strokeOpacity: 1,
              draggable: self.sheet.form,
              editable: self.sheet.form,
              clickable: self.sheet.form,
              zIndex: 1
            }
          })

          drawingManager.setMap(self.map)

          drawingManager.addListener('polygoncomplete', (polygon) => {
            if (polygon.getPath().getLength() < 3) {
              console.log('Polygons must have 3 or more points.')
              polygon.getPath().clear()
            } else {
              self.addFeature('Polygon', polygon.getPath())
              polygon.setMap(null)
            }
          })

          if (this.detail.ajuan_koordinat.length > 0) self.addFeature('Polygon', this.detail.ajuan_koordinat)
        })
      },
      featureAdded (e) {
        switch (e.feature.getGeometry().getType()) {
          case 'Polygon':
            addFeature('Polygon', e.feature.getGeometry().getAt(0).getArray())
            break
        }

        self.map.data.remove(e.feature)
      },
      addFeature (type, path) {
        let self = this

        switch (type) {
          case 'Polygon':
            let polygon = new google.maps.Polygon({
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              strokeColor: '#FF0000',
              strokeWeight: 2,
              strokeOpacity: 1,
              draggable: self.sheet.form,
              editable: self.sheet.form,
              clickable: self.sheet.form,
              zIndex: 1
            })

            polygon.setPath(path)

            polygon.getPath().addListener('set_at', (e, obj) => {
              let polypath = polygon.getPath()
              self.form.koordinat = []

              for (var i = 0; i < polypath.length; i++) {
                self.form.koordinat.push({
                  lat: polypath.getAt(i).lat(),
                  lng: polypath.getAt(i).lng()
                })
              }
            })

            polygon.addListener('dragend', (e) => {
              let polypath = polygon.getPath()
              self.form.koordinat = []
              let bounds = new google.maps.LatLngBounds()

              for (var i = 0; i < polypath.length; i++) {
                bounds.extend(polypath.getAt(i))

                self.form.koordinat.push({
                  lat: polypath.getAt(i).lat(),
                  lng: polypath.getAt(i).lng()
                })
              }
            })

            polygon.addListener('rightclick', (e) => {
              if (e.vertex == undefined) return

              if (polygon.getPath().getLength() === 3) {
                polygon.setMap(null)
                self.form.koordinat = []
              } else {
                polygon.getPath().removeAt(e.vertex)
                self.form.koordinat.splice(e.vertex, 1)
              }
            })

            let data_polygon = polygon.getPath()

            if (self.detail.ajuan_koordinat.length < 1) {
              for (let i = 0; i < data_polygon.getLength(); i++) {
                self.form.koordinat.push({
                  lat: parseFloat(data_polygon.getAt(i).toUrlValue(10).split(',')[0]),
                  lng: parseFloat(data_polygon.getAt(i).toUrlValue(10).split(',')[1])
                })

                const lats = self.form.koordinat.map(o => o.lat)
                self.form.koordinat = self.form.koordinat.filter(({lat}, index) => !lats.includes(lat, index + 1))
              }
            }

            polygon.setMap(self.map)
            break

        }
      },

      addLatlng () {
        this.form.koordinat.push({
          lat: null,
          lng: null
        })
      },
      removeLatlng (index) {
        this.form.koordinat.splice(index, 1)

        this.initLatlng()
      },
      initLatlng () {
        let self = this

        if (this.form.koordinat.length > 2) {
          if (this.form.koordinat[2].lat !== '' && this.form.koordinat[2].lat !== null && this.form.koordinat[2].lng !== '' && this.form.koordinat[2].lng !== null) {
            this.$gmapApiPromiseLazy().then(() => {
              self.map = new google.maps.Map(document.getElementById('map'), {
                center: { 
                  lat: this.form.koordinat.length > 0 ? this.form.koordinat[0].lat : -7.71210962,
                  lng: this.form.koordinat.length > 0 ? this.form.koordinat[0].lng : 110.59174574
                },
                zoom: 18,
                noClear: true,
                mapTypeId: 'hybrid', //satellite
                navigationControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                tilt: 0
              })
    
              self.map.data.addListener('addfeature', self.featureAdded)
    
              const drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: this.form.koordinat.length < 1 ? google.maps.drawing.OverlayType.POLYGON : null,
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT,
                  drawingModes: ['polygon']
                },
                polygonOptions: {
                  fillColor: '#FF0000',
                  fillOpacity: 0.35,
                  strokeColor: '#FF0000',
                  strokeWeight: 2,
                  strokeOpacity: 1,
                  draggable: true,
                  editable: true,
                  clickable: true,
                  zIndex: 1
                }
              })
    
              drawingManager.setMap(self.map)
    
              drawingManager.addListener('polygoncomplete', (polygon) => {
                if (polygon.getPath().getLength() < 3) {
                  console.log('Polygons must have 3 or more points.')
                  polygon.getPath().clear()
                } else {
                  self.addFeature('Polygon', polygon.getPath())
                  polygon.setMap(null)
                }
              })
    
              if (this.form.koordinat.length > 0) self.addFeature('Polygon', this.form.koordinat)
            })
          }
        } else {
          this.initMap()
        }
      },
      
      async save () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/update`, this.form)
        .then((response) => {

          if (response.code === 200) {
            this.sheet.form = false

            this.reset()

            this.$emit('load', false)
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>