<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            color="white"
            class="box-shadow border-radius">
            <v-card-text>
              <div
                class="title font-weight-bold black--text mb-1">
                Log Status Ajuan
              </div>
              
              <div
                class="d-flex align-center">
                <div>
                  Berikut adalah prubahan log status ajuan izin usaha.
                </div>
              </div>
            </v-card-text>

            <div>
              <v-avatar
                size="30"
                color="#2391d0"
                class="mx-8">
                <v-icon color="#FFFFFF">mdi-progress-download</v-icon>
              </v-avatar>

              <v-timeline
                align-center
                dense>
                <v-timeline-item
                  v-for="(item, index) in process.load ? 5 : list"
                  :key="index"
                  small
                  :color="item.log_status === 'MENUNGGU VERIFIKASI' ? 'orange' :
                          item.log_status === 'DIPROSES' ? 'blue' :
                          item.log_status === 'DITOLAK' ? 'red' :
                          item.log_status === 'DIKOREKSI' ? 'purple' :
                          item.log_status === 'DISETUJUI' ? 'green' : 'red'">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="article">
                    <div>
                      <v-row v-if="!process.load && list.length > 0"
                        class="cursor-pointer">
                        <v-col 
                          cols="3">
                          <strong
                            class="black--text"
                            style="opacity: 60%;">
                            {{ item.log_create_datetime | time }}
                          </strong>
                        </v-col>

                        <v-col
                          cols="9">
                          <strong
                            :class="item.log_status === 'MENUNGGU VERIFIKASI' ? 'orange--text' :
                                    item.log_status === 'DIPROSES' ? 'blue--text' :
                                    item.log_status === 'DITOLAK' ? 'red--text' :
                                    item.log_status === 'DIKOREKSI' ? 'purple--text' :
                                    item.log_status === 'DISETUJUI' ? 'green--text' : 'red--text'">
                            {{ item.log_status }}
                          </strong>

                          <div
                            class="body-2 font-weight-light line-text-second"
                            style="opacity: 60%;">
                            <span
                              v-html="item.log_note.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </div>

                          <div 
                            class="caption font-weight-bold black--text"
                            style="opacity: 60%;">
                            {{ item.log_create_datetime | date }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-skeleton-loader>
                </v-timeline-item>

                <v-timeline-item v-if="!process.load && list.length < 1"
                  small
                  color="red">
                  <v-row>
                    <v-col 
                      cols="12">
                      <Empty
                        size="150"
                        message="Log Status" />
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        list: [],
        pagination: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          limit: 100000,
          log_ajuan_id: this.detail.ajuan_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/log`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>